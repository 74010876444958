.devices,
.yellowbg {
  position: relative;
}

.laptop,
.mobile,
.tablet,
.yellow-img {
  position: absolute;
}

.main-banner .row {
  height: 42rem;
  /* background-color: #dbe2e9;*/
  background:  linear-gradient(90.29deg, #B4D0EE 0.25%, rgba(180, 208, 238, 0.68) 48.37%, rgba(219, 226, 233, 0) 99.77%);;
  margin-top: 3rem;
}

.cloudStudio {
  padding: 4rem 5rem;
}

.cloudStudio h3.header-text {
  color: #082648;
  font-weight: 600;
  font-size: 38px;
}
.cloudStudio p.para-text-1 {
  color: #082648;
  font-weight: 400;
  font-size: 18px;
}

.cloudStudio p.para-text-2 {
  color: #082648;
  font-weight: 600;
  font-size: 18px;
}
.cloudStudio p.para-text-2 span {
  background: url("../../assets/images/yellow-highlighter.png") no-repeat;
  padding: 1rem 0rem;
  background-size: 23rem 4rem;
}
button.demo-button {
  background: #ffffff !important;
  box-shadow: 0px 2.27826px 2.27826px rgba(217, 217, 217, 0.6);
  border-radius: 13px;
  font-weight: 500;
  font-size: 18px !important;
  padding: 1.3rem 3rem;
  margin-top: 1rem;
  color: #082648 !important;
}

.yellow-img {
  z-index: 1;
  right: 0;
  top: -2rem;
}

.yellow-img img {
  height: 50rem;
  width: 38rem;
}

.laptop {
  top: 25rem;
  right: 29rem;
  z-index: 2;
}

.laptop img {
  height: 24rem;
}
.tablet img {
  height: 20rem;
}
.mobile img {
  height: 20rem;
}
.tablet {
  z-index: 2;
  right: 16rem;
  top: 5rem;
}
.mobile {
  top: 23rem;
  right: 9rem;
  z-index: 2;
}
.asset-management {
  margin-top: 13rem;
  text-align: center;
  margin-bottom: 5rem;
}

.asset-header h3,
.why-cloudstudio span {
  font-weight: 600;
  font-size: 30px;
  color: #082648;
}
.why-cloudstudio h3 {
  font-weight: 400;
  font-size: 25px;
  color: #082648;
}

.asset-header p,
.why-cloudstudio p {
  padding-top: 1rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 2;
}

.asset-header span {
  font-weight: 600;
}

/* circles section */
.circle-carousel {
  margin: 2rem;
  padding: 2rem 3rem;
  position: relative;
}
.circles {
  position: relative;
}
.circle-1,
.circle-2,
.circle-3,
.circle-4,
.circle-5 {
  position: absolute;
  cursor: pointer;
}

.circle-small,
.circle-large {
  height: 170px;
  width: 170px;
  background: #082648;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2.83988px 2.83988px rgba(7, 24, 43, 0.25);
  transition: all 0.3s;
}

.circle-large {
  height: 244px;
  width: 244px;
}

.initial-active-large {
  height: 244px;
  width: 244px;
  background: #082648;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2.83988px 2.83988px rgba(7, 24, 43, 0.25);
  transition: all 0.3s;
  color: #fbce01;
}

.initial-active-large .circle-header {
  font-size: 16px;
}
.initial-active-large ul li {
  font-size: 13px;
  line-height: 2;
  color: #fbce01;
}

.circle-large .circle-header {
  font-size: 16px;
}

.circle-1 ul,
.circle-2 ul,
.circle-3 ul,
.circle-4 ul {
  padding: 0;
}
.circle-header {
  font-size: 14px;
  font-weight: 600;
  color: #fbce01;
}

.circle-1 ul li,
.circle-2 ul li,
.circle-3 ul li,
.circle-4 ul li {
  color: #fbce01;
  font-weight: 400;
  font-size: 10px;
  line-height: 1.5;
}
.circle-large ul li {
  font-size: 13px;
  line-height: 2;
}
.circle-5 {
  height: 250px;
  width: 250px;
  background: #fbce01;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-5 span {
  color: #082648;
  font-size: 18px;
  font-weight: 500;
}

.circle-2.circle-small {
  left: 31rem;
}
.circle-5 {
  top: 6rem;
  left: 16rem;
  z-index: -1;
}

.circle-1.circle-small {
  left: 7.3rem;
}
.circle-1.circle-large {
  top: -4rem;
}

.circle-2.circle-large {
  left: 27rem;
  top: -5rem;
}

.circle-4.circle-small {
  top: 18rem;
  left: 30rem;
}

.circle-4.circle-large {
  top: 17rem;
  left: 27rem;
}
.circle-3.circle-small {
  top: 17rem;
  left: 7rem;
}

/* circle-description */
.circle-description {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle-description h3 {
  font-size: 30px;
  color: #082648;
  font-weight: 500;
}

.circle-description p {
  line-height: 1.8;
  font-size: 17px;
  color: #082648;
}
.circle-3.circle-large {
  top: 17rem;
}
.carousel-section {
  background-color: #dbe2e9;
}

.carousel-inner-padding {
  padding: 5rem 10rem;
}

.carousel-left-image img {
  height: 20rem;
}

.carousel-2-height img {
  height: 10rem;
}

.carousel.slide {
  height: 19rem;
}

.highlight {
  font-weight: 600;
}
.carousel-text {
  font-size: 17px;
  color: #082648;
  line-height: 2;
  padding: 0 7rem;
  text-align: right;
}

.carousel-text span {
  font-weight: 600;
}

.want-to-know-more p {
  font-size: 18px;
  font-weight: 400;
  color: #082648;
  display: flex;
  align-items: center;
}

.want-to-know-more div p span button {
  font-size: 18px !important;
  font-weight: 400;
  color: #fff !important;
  background: #082648 !important;
  padding: 1.3rem 3rem;
  border-radius: 12px;
}

.why-cloudstudio {
  margin-top: 10rem;
}

footer {
  background: #fafafb;
}

.copyrights {
  border-top: 1px solid #d9d9d9;
}

.footer-padding {
  padding: 2rem 1rem;
}

footer ul {
  padding: 0;
}

footer ul li {
  line-height: 2.5;
  color: black;
  font-size: 14px;
  cursor: pointer;
}

footer h4 {
  border-bottom: 1px solid #000;
  padding-bottom: 0.7rem;
}

.about {
  padding: 0 7rem 0 0;
}

.footer-logo {
  padding: 1rem 0 3rem 0;
}
.social-media-logos {
  font-size: 16px;
  font-weight: 500;
  padding: 0 0 0 1rem;
  display: flex;
  width: 100%;
  align-items: center;
}

.social-media-logos span.follow-text {
  padding: 0 3rem 0 0;
}

.social-media-logos span.social-icons {
  font-size: 35px;
  display: flex;
}
.social-media-logos span.social-icons div {
  padding-right: 2rem;
}

.carousel-inner-padding .carousel-indicators {
  bottom: -3rem;
}
.carousel-inner-padding .carousel-indicators button {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: none;
  background: white;
  margin-right: 1rem;
}

.carousel-indicators button.active {
  background: #082648;
}
