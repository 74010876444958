@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --spp_site_color: #1c344c;
  --spp_background_color: #f8f8f8;
  --spp_lite_site_color: #e7e7e7;
  --white-color: #fff;
}
* {
  font-family: "Poppins" !important;
}
.header-title-spp {
  font-weight: 600;
  font-size: 22px;
  color: #1c344c;
  padding: 1rem 2rem 1rem 1rem;
}
.spp-invoice {
  margin-right: 4% !important ;
  margin-left: 4% !important ;
  background-color: var(--spp_background_color);
}
.spp_bg {
  background-color: var(--spp_background_color);
}
.date-picker-container {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center;
  float: right !important;
}
.date-picker {
  color: var(--spp_site_color) !important;
  border-radius: 5px;
  margin-left: 10px !important;
  margin-bottom: 5%;
  font-size: 16px;
  padding: 5px;
  background-color: #fff !important;
  border: 1px solid #fff !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  float: right !important;
}
.search-text {
  width: 200px !important;
  height: 35px !important;
  margin-bottom: 5% !important;
}


.spp_Table.ant-table-wrapper .ant-table-thead > tr > th {
  color: #fff !important;
  background-color: var(--spp_site_color) !important;;

}
.ant-table-wrapper {

  min-width: 100%;}
.spp.spp_Table.ant-table-wrapper .ant-table {
  background-color: #fff !important;
  color: var(--spp_site_color) !important;
  overflow-x: auto !important;
} 

.tab-btn {
  background-color: #fff !important;
  border: none !important;
  box-shadow: none !important;
  color: var(--spp_site_color) !important;
}
.drop-down-list .ant-dropdown-menu {
  background-color: var(--spp_site_color) !important;
  color: #fff !important;
  width: 162px !important;
}

.drop-down-list .ant-dropdown-menu .ant-dropdown-menu-item {
  color: #fff !important;
}
.popupbtn {
  background-color: var(--spp_site_color) !important;
  box-shadow: 0px 6.07109px 6.07109px rgba(18, 31, 44, 0.15) !important;
  width: 150.53px !important;
  color: #fff !important;
}
.drop-down-list.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item-divider {
  background-color: #fff !important;
}
.reset-btn {
  background-color: var(--spp_site_color) !important;
  margin-bottom: 5%;
  margin-left: 1%;
}

.spp-date{
  width: 100% !important;
}
.spp{
  width: 100% !important;
  overflow-x: auto !important;
}
.spp-loader{
  color: var(--spp_site_color) !important;
  width: 100% !important;
}
.spp-loader .ant-spin .ant-spin-dot-item {
  background-color: var(--spp_site_color) !important;
}
.right-aligned {
    text-align: right !important;
}
.download-btn{
  font-size: 21px !important;
  /* color:rgb(112, 21, 21); */
  color: #C70000 !important;
}
.view-text{
  font-size: 18px !important;
}






