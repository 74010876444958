:root {
  --site_color: #1c344c;
}
ol,
ul {
  list-style: none;
}

.grid {
  --grid-gap: 0px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.grid > * {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
[class*="grid-gap"] {
  margin-bottom: 1em * -1;
  margin-bottom: calc(var(--grid-gap, 1em) * -1);
  margin-right: 1em * -1;
  margin-right: calc(var(--grid-gap, 1em) * -1);
}
[class*="grid-gap"] > * {
  margin-bottom: 1em;
  margin-bottom: var(--grid-gap, 1em);
  margin-right: 1em;
  margin-right: var(--grid-gap, 1em);
}
.grid-gap-xxxxs {
  --grid-gap: var(--space-xxxxs);
}
.grid-gap-xxxs {
  --grid-gap: var(--space-xxxs);
}
.grid-gap-xxs {
  --grid-gap: var(--space-xxs);
}
.grid-gap-xs {
  --grid-gap: var(--space-xs);
}
.grid-gap-sm {
  --grid-gap: var(--space-sm);
}
.grid-gap-md {
  --grid-gap: var(--space-md);
}
.grid-gap-lg {
  --grid-gap: var(--space-lg);
}
.grid-gap-xl {
  --grid-gap: var(--space-xl);
}
.grid-gap-xxl {
  --grid-gap: var(--space-xxl);
}
.grid-gap-xxxl {
  --grid-gap: var(--space-xxxl);
}
.grid-gap-xxxxl {
  --grid-gap: var(--space-xxxxl);
}
.col {
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
}
.col-1 {
  -ms-flex-preferred-size: calc(8.33% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(8.33% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(8.33% - 0.01px - 1em);
  flex-basis: calc(8.33% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(8.33% - 0.01px - 1em);
  max-width: calc(8.33% - 0.01px - var(--grid-gap, 1em));
}
.col-2 {
  -ms-flex-preferred-size: calc(16.66% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(16.66% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(16.66% - 0.01px - 1em);
  flex-basis: calc(16.66% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(16.66% - 0.01px - 1em);
  max-width: calc(16.66% - 0.01px - var(--grid-gap, 1em));
}
.col-3 {
  -ms-flex-preferred-size: calc(25% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(25% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(25% - 0.01px - 1em);
  flex-basis: calc(25% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(25% - 0.01px - 1em);
  max-width: calc(25% - 0.01px - var(--grid-gap, 1em));
}
.col-4 {
  -ms-flex-preferred-size: calc(33.33% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(33.33% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(33.33% - 0.01px - 1em);
  flex-basis: calc(33.33% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(33.33% - 0.01px - 1em);
  max-width: calc(33.33% - 0.01px - var(--grid-gap, 1em));
}
.col-5 {
  -ms-flex-preferred-size: calc(41.66% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(41.66% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(41.66% - 0.01px - 1em);
  flex-basis: calc(41.66% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(41.66% - 0.01px - 1em);
  max-width: calc(41.66% - 0.01px - var(--grid-gap, 1em));
}
.col-6 {
  -ms-flex-preferred-size: calc(50% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(50% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(50% - 0.01px - 1em);
  flex-basis: calc(50% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(50% - 0.01px - 1em);
  max-width: calc(50% - 0.01px - var(--grid-gap, 1em));
}
.col-7 {
  -ms-flex-preferred-size: calc(58.33% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(58.33% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(58.33% - 0.01px - 1em);
  flex-basis: calc(58.33% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(58.33% - 0.01px - 1em);
  max-width: calc(58.33% - 0.01px - var(--grid-gap, 1em));
}
.col-8 {
  -ms-flex-preferred-size: calc(66.66% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(66.66% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(66.66% - 0.01px - 1em);
  flex-basis: calc(66.66% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(66.66% - 0.01px - 1em);
  max-width: calc(66.66% - 0.01px - var(--grid-gap, 1em));
}
.col-9 {
  -ms-flex-preferred-size: calc(75% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(75% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(75% - 0.01px - 1em);
  flex-basis: calc(75% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(75% - 0.01px - 1em);
  max-width: calc(75% - 0.01px - var(--grid-gap, 1em));
}
.col-10 {
  -ms-flex-preferred-size: calc(83.33% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(83.33% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(83.33% - 0.01px - 1em);
  flex-basis: calc(83.33% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(83.33% - 0.01px - 1em);
  max-width: calc(83.33% - 0.01px - var(--grid-gap, 1em));
}
.col-11 {
  -ms-flex-preferred-size: calc(91.66% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(91.66% - 0.01px - 1em);
  flex-basis: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(91.66% - 0.01px - 1em);
  max-width: calc(91.66% - 0.01px - var(--grid-gap, 1em));
}
.col-12 {
  -ms-flex-preferred-size: calc(100% - 0.01px - 1em);
  -ms-flex-preferred-size: calc(100% - 0.01px - var(--grid-gap, 1em));
  flex-basis: calc(100% - 0.01px - 1em);
  flex-basis: calc(100% - 0.01px - var(--grid-gap, 1em));
  max-width: calc(100% - 0.01px - 1em);
  max-width: calc(100% - 0.01px - var(--grid-gap, 1em));
}
@media (min-width: 32rem) {
  .col\@xs {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@xs {
    -ms-flex-preferred-size: calc(8.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(8.33% - 0.01px - 1em);
    flex-basis: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(8.33% - 0.01px - 1em);
    max-width: calc(8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@xs {
    -ms-flex-preferred-size: calc(16.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(16.66% - 0.01px - 1em);
    flex-basis: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(16.66% - 0.01px - 1em);
    max-width: calc(16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@xs {
    -ms-flex-preferred-size: calc(25% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(25% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(25% - 0.01px - 1em);
    flex-basis: calc(25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(25% - 0.01px - 1em);
    max-width: calc(25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@xs {
    -ms-flex-preferred-size: calc(33.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(33.33% - 0.01px - 1em);
    flex-basis: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(33.33% - 0.01px - 1em);
    max-width: calc(33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@xs {
    -ms-flex-preferred-size: calc(41.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(41.66% - 0.01px - 1em);
    flex-basis: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(41.66% - 0.01px - 1em);
    max-width: calc(41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@xs {
    -ms-flex-preferred-size: calc(50% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(50% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(50% - 0.01px - 1em);
    flex-basis: calc(50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(50% - 0.01px - 1em);
    max-width: calc(50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@xs {
    -ms-flex-preferred-size: calc(58.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(58.33% - 0.01px - 1em);
    flex-basis: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(58.33% - 0.01px - 1em);
    max-width: calc(58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@xs {
    -ms-flex-preferred-size: calc(66.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(66.66% - 0.01px - 1em);
    flex-basis: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(66.66% - 0.01px - 1em);
    max-width: calc(66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@xs {
    -ms-flex-preferred-size: calc(75% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(75% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(75% - 0.01px - 1em);
    flex-basis: calc(75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(75% - 0.01px - 1em);
    max-width: calc(75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@xs {
    -ms-flex-preferred-size: calc(83.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(83.33% - 0.01px - 1em);
    flex-basis: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(83.33% - 0.01px - 1em);
    max-width: calc(83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@xs {
    -ms-flex-preferred-size: calc(91.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(91.66% - 0.01px - 1em);
    flex-basis: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(91.66% - 0.01px - 1em);
    max-width: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@xs {
    -ms-flex-preferred-size: calc(100% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(100% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(100% - 0.01px - 1em);
    flex-basis: calc(100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(100% - 0.01px - 1em);
    max-width: calc(100% - 0.01px - var(--grid-gap, 1em));
  }
}
@media (min-width: 48rem) {
  .col\@sm {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@sm {
    -ms-flex-preferred-size: calc(8.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(8.33% - 0.01px - 1em);
    flex-basis: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(8.33% - 0.01px - 1em);
    max-width: calc(8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@sm {
    -ms-flex-preferred-size: calc(16.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(16.66% - 0.01px - 1em);
    flex-basis: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(16.66% - 0.01px - 1em);
    max-width: calc(16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@sm {
    -ms-flex-preferred-size: calc(25% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(25% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(25% - 0.01px - 1em);
    flex-basis: calc(25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(25% - 0.01px - 1em);
    max-width: calc(25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@sm {
    -ms-flex-preferred-size: calc(33.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(33.33% - 0.01px - 1em);
    flex-basis: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(33.33% - 0.01px - 1em);
    max-width: calc(33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@sm {
    -ms-flex-preferred-size: calc(41.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(41.66% - 0.01px - 1em);
    flex-basis: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(41.66% - 0.01px - 1em);
    max-width: calc(41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@sm {
    -ms-flex-preferred-size: calc(50% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(50% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(50% - 0.01px - 1em);
    flex-basis: calc(50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(50% - 0.01px - 1em);
    max-width: calc(50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@sm {
    -ms-flex-preferred-size: calc(58.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(58.33% - 0.01px - 1em);
    flex-basis: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(58.33% - 0.01px - 1em);
    max-width: calc(58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@sm {
    -ms-flex-preferred-size: calc(66.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(66.66% - 0.01px - 1em);
    flex-basis: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(66.66% - 0.01px - 1em);
    max-width: calc(66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@sm {
    -ms-flex-preferred-size: calc(75% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(75% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(75% - 0.01px - 1em);
    flex-basis: calc(75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(75% - 0.01px - 1em);
    max-width: calc(75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@sm {
    -ms-flex-preferred-size: calc(83.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(83.33% - 0.01px - 1em);
    flex-basis: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(83.33% - 0.01px - 1em);
    max-width: calc(83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@sm {
    -ms-flex-preferred-size: calc(91.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(91.66% - 0.01px - 1em);
    flex-basis: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(91.66% - 0.01px - 1em);
    max-width: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@sm {
    -ms-flex-preferred-size: calc(100% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(100% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(100% - 0.01px - 1em);
    flex-basis: calc(100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(100% - 0.01px - 1em);
    max-width: calc(100% - 0.01px - var(--grid-gap, 1em));
  }
}
@media (min-width: 64rem) {
  .col\@md {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@md {
    -ms-flex-preferred-size: calc(8.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(8.33% - 0.01px - 1em);
    flex-basis: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(8.33% - 0.01px - 1em);
    max-width: calc(8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@md {
    -ms-flex-preferred-size: calc(16.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(16.66% - 0.01px - 1em);
    flex-basis: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(16.66% - 0.01px - 1em);
    max-width: calc(16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@md {
    -ms-flex-preferred-size: calc(25% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(25% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(25% - 0.01px - 1em);
    flex-basis: calc(25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(25% - 0.01px - 1em);
    max-width: calc(25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@md {
    -ms-flex-preferred-size: calc(33.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(33.33% - 0.01px - 1em);
    flex-basis: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(33.33% - 0.01px - 1em);
    max-width: calc(33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@md {
    -ms-flex-preferred-size: calc(41.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(41.66% - 0.01px - 1em);
    flex-basis: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(41.66% - 0.01px - 1em);
    max-width: calc(41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@md {
    -ms-flex-preferred-size: calc(50% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(50% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(50% - 0.01px - 1em);
    flex-basis: calc(50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(50% - 0.01px - 1em);
    max-width: calc(50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@md {
    -ms-flex-preferred-size: calc(58.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(58.33% - 0.01px - 1em);
    flex-basis: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(58.33% - 0.01px - 1em);
    max-width: calc(58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@md {
    -ms-flex-preferred-size: calc(66.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(66.66% - 0.01px - 1em);
    flex-basis: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(66.66% - 0.01px - 1em);
    max-width: calc(66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@md {
    -ms-flex-preferred-size: calc(75% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(75% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(75% - 0.01px - 1em);
    flex-basis: calc(75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(75% - 0.01px - 1em);
    max-width: calc(75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@md {
    -ms-flex-preferred-size: calc(83.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(83.33% - 0.01px - 1em);
    flex-basis: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(83.33% - 0.01px - 1em);
    max-width: calc(83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@md {
    -ms-flex-preferred-size: calc(91.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(91.66% - 0.01px - 1em);
    flex-basis: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(91.66% - 0.01px - 1em);
    max-width: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@md {
    -ms-flex-preferred-size: calc(100% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(100% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(100% - 0.01px - 1em);
    flex-basis: calc(100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(100% - 0.01px - 1em);
    max-width: calc(100% - 0.01px - var(--grid-gap, 1em));
  }
}
@media (min-width: 80rem) {
  .col\@lg {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@lg {
    -ms-flex-preferred-size: calc(8.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(8.33% - 0.01px - 1em);
    flex-basis: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(8.33% - 0.01px - 1em);
    max-width: calc(8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@lg {
    -ms-flex-preferred-size: calc(16.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(16.66% - 0.01px - 1em);
    flex-basis: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(16.66% - 0.01px - 1em);
    max-width: calc(16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@lg {
    -ms-flex-preferred-size: calc(25% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(25% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(25% - 0.01px - 1em);
    flex-basis: calc(25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(25% - 0.01px - 1em);
    max-width: calc(25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@lg {
    -ms-flex-preferred-size: calc(33.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(33.33% - 0.01px - 1em);
    flex-basis: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(33.33% - 0.01px - 1em);
    max-width: calc(33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@lg {
    -ms-flex-preferred-size: calc(41.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(41.66% - 0.01px - 1em);
    flex-basis: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(41.66% - 0.01px - 1em);
    max-width: calc(41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@lg {
    -ms-flex-preferred-size: calc(50% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(50% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(50% - 0.01px - 1em);
    flex-basis: calc(50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(50% - 0.01px - 1em);
    max-width: calc(50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@lg {
    -ms-flex-preferred-size: calc(58.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(58.33% - 0.01px - 1em);
    flex-basis: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(58.33% - 0.01px - 1em);
    max-width: calc(58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@lg {
    -ms-flex-preferred-size: calc(66.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(66.66% - 0.01px - 1em);
    flex-basis: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(66.66% - 0.01px - 1em);
    max-width: calc(66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@lg {
    -ms-flex-preferred-size: calc(75% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(75% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(75% - 0.01px - 1em);
    flex-basis: calc(75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(75% - 0.01px - 1em);
    max-width: calc(75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@lg {
    -ms-flex-preferred-size: calc(83.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(83.33% - 0.01px - 1em);
    flex-basis: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(83.33% - 0.01px - 1em);
    max-width: calc(83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@lg {
    -ms-flex-preferred-size: calc(91.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(91.66% - 0.01px - 1em);
    flex-basis: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(91.66% - 0.01px - 1em);
    max-width: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@lg {
    -ms-flex-preferred-size: calc(100% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(100% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(100% - 0.01px - 1em);
    flex-basis: calc(100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(100% - 0.01px - 1em);
    max-width: calc(100% - 0.01px - var(--grid-gap, 1em));
  }
}
@media (min-width: 90rem) {
  .col\@xl {
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 100%;
  }
  .col-1\@xl {
    -ms-flex-preferred-size: calc(8.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(8.33% - 0.01px - 1em);
    flex-basis: calc(8.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(8.33% - 0.01px - 1em);
    max-width: calc(8.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-2\@xl {
    -ms-flex-preferred-size: calc(16.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(16.66% - 0.01px - 1em);
    flex-basis: calc(16.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(16.66% - 0.01px - 1em);
    max-width: calc(16.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-3\@xl {
    -ms-flex-preferred-size: calc(25% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(25% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(25% - 0.01px - 1em);
    flex-basis: calc(25% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(25% - 0.01px - 1em);
    max-width: calc(25% - 0.01px - var(--grid-gap, 1em));
  }
  .col-4\@xl {
    -ms-flex-preferred-size: calc(33.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(33.33% - 0.01px - 1em);
    flex-basis: calc(33.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(33.33% - 0.01px - 1em);
    max-width: calc(33.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-5\@xl {
    -ms-flex-preferred-size: calc(41.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(41.66% - 0.01px - 1em);
    flex-basis: calc(41.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(41.66% - 0.01px - 1em);
    max-width: calc(41.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-6\@xl {
    -ms-flex-preferred-size: calc(50% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(50% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(50% - 0.01px - 1em);
    flex-basis: calc(50% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(50% - 0.01px - 1em);
    max-width: calc(50% - 0.01px - var(--grid-gap, 1em));
  }
  .col-7\@xl {
    -ms-flex-preferred-size: calc(58.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(58.33% - 0.01px - 1em);
    flex-basis: calc(58.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(58.33% - 0.01px - 1em);
    max-width: calc(58.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-8\@xl {
    -ms-flex-preferred-size: calc(66.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(66.66% - 0.01px - 1em);
    flex-basis: calc(66.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(66.66% - 0.01px - 1em);
    max-width: calc(66.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-9\@xl {
    -ms-flex-preferred-size: calc(75% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(75% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(75% - 0.01px - 1em);
    flex-basis: calc(75% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(75% - 0.01px - 1em);
    max-width: calc(75% - 0.01px - var(--grid-gap, 1em));
  }
  .col-10\@xl {
    -ms-flex-preferred-size: calc(83.33% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(83.33% - 0.01px - 1em);
    flex-basis: calc(83.33% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(83.33% - 0.01px - 1em);
    max-width: calc(83.33% - 0.01px - var(--grid-gap, 1em));
  }
  .col-11\@xl {
    -ms-flex-preferred-size: calc(91.66% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(91.66% - 0.01px - 1em);
    flex-basis: calc(91.66% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(91.66% - 0.01px - 1em);
    max-width: calc(91.66% - 0.01px - var(--grid-gap, 1em));
  }
  .col-12\@xl {
    -ms-flex-preferred-size: calc(100% - 0.01px - 1em);
    -ms-flex-preferred-size: calc(100% - 0.01px - var(--grid-gap, 1em));
    flex-basis: calc(100% - 0.01px - 1em);
    flex-basis: calc(100% - 0.01px - var(--grid-gap, 1em));
    max-width: calc(100% - 0.01px - 1em);
    max-width: calc(100% - 0.01px - var(--grid-gap, 1em));
  }
}
:root {
  --radius-sm: calc(var(--radius, 0.25em) / 2);
  --radius-md: var(--radius, 0.25em);
  --radius-lg: calc(var(--radius, 0.25em) * 2);
  --shadow-sm: 0 1px 2px rgba(0, 0, 0, 0.085), 0 1px 8px rgba(0, 0, 0, 0.1);
  --shadow-md: 0 1px 8px rgba(0, 0, 0, 0.1), 0 8px 24px rgba(0, 0, 0, 0.15);
  --shadow-lg: 0 1px 8px rgba(0, 0, 0, 0.1), 0 16px 48px rgba(0, 0, 0, 0.1),
    0 24px 60px rgba(0, 0, 0, 0.1);
  --bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  --ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-out: cubic-bezier(0.215, 0.61, 0.355, 1);
}
:root {
  --body-line-height: 1.4;
  --heading-line-height: 1.2;
}

.text-xxxl {
  font-size: 2.48832em;
  font-size: var(--text-xxxl, 2.488em);
}
h1,
.text-xxl {
  font-size: 2.0736em;
  font-size: var(--text-xxl, 2.074em);
}
h2,
.text-xl {
  font-size: 1.728em;
  font-size: var(--text-xl, 1.728em);
}
h3,
.text-lg {
  font-size: 1.44em;
  font-size: var(--text-lg, 1.44em);
}
h4,
.text-md {
  font-size: 1.2em;
  font-size: var(--text-md, 1.2em);
}
small,
.text-sm {
  font-size: 0.83333em;
  font-size: var(--text-sm, 0.833em);
}
.text-xs {
  font-size: 0.69444em;
  font-size: var(--text-xs, 0.694em);
}
/* a,
.link {
  color: hsl(220, 90%, 56%);
  color: var(--color-primary, #2a6df4);
  text-decoration: underline;
} */
strong,
.text-bold {
  font-weight: bold;
}
s {
  text-decoration: line-through;
}
u,
.text-underline {
  text-decoration: underline;
}
.text-component {
  --component-body-line-height: calc(
    var(--body-line-height) * var(--line-height-multiplier, 1)
  );
  --component-heading-line-height: calc(
    var(--heading-line-height) * var(--line-height-multiplier, 1)
  );
}
.text-component h1,
.text-component h2,
.text-component h3,
.text-component h4 {
  line-height: 1.2;
  line-height: var(--component-heading-line-height, 1.2);
  margin-bottom: 0.25em;
  margin-bottom: calc(var(--space-xxxs) * var(--text-vspace-multiplier, 1));
}
.text-component h2,
.text-component h3,
.text-component h4 {
  margin-top: 0.75em;
  margin-top: calc(var(--space-sm) * var(--text-vspace-multiplier, 1));
}
.text-component p,
.text-component blockquote,
.text-component ul li,
.text-component ol li {
  line-height: 1.4;
  line-height: var(--component-body-line-height);
}
.text-component ul,
.text-component ol,
.text-component p,
.text-component blockquote,
.text-component .text-component__block {
  margin-bottom: 0.75em;
  margin-bottom: calc(var(--space-sm) * var(--text-vspace-multiplier, 1));
}
.text-component ul,
.text-component ol {
  padding-left: 1em;
}
.text-component ul {
  list-style-type: disc;
}
.text-component ol {
  list-style-type: decimal;
}
.text-component img {
  display: block;
  margin: 0 auto;
}
.text-component figcaption {
  text-align: center;
  margin-top: 0.5em;
  margin-top: var(--space-xs);
}
.text-component em {
  font-style: italic;
}
.text-component hr {
  margin-top: 2em;
  margin-top: calc(var(--space-lg) * var(--text-vspace-multiplier, 1));
  margin-bottom: 2em;
  margin-bottom: calc(var(--space-lg) * var(--text-vspace-multiplier, 1));
  margin-left: auto;
  margin-right: auto;
}
.text-component > *:first-child {
  margin-top: 0;
}
.text-component > *:last-child {
  margin-bottom: 0;
}
.text-component__block--full-width {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}
@media (min-width: 48rem) {
  .text-component__block--left,
  .text-component__block--right {
    width: 45%;
  }
  .text-component__block--left img,
  .text-component__block--right img {
    width: 100%;
  }
  .text-component__block--left {
    float: left;
    margin-right: 0.75em;
    margin-right: calc(var(--space-sm) * var(--text-vspace-multiplier, 1));
  }
  .text-component__block--right {
    float: right;
    margin-left: 0.75em;
    margin-left: calc(var(--space-sm) * var(--text-vspace-multiplier, 1));
  }
}
@media (min-width: 90rem) {
  .text-component__block--outset {
    width: calc(100% + 10.5em);
    width: calc(100% + 2 * var(--space-xxl));
  }
  .text-component__block--outset img {
    width: 100%;
  }
  .text-component__block--outset:not(.text-component__block--right) {
    margin-left: -5.25em;
    margin-left: calc(-1 * var(--space-xxl));
  }
  .text-component__block--left,
  .text-component__block--right {
    width: 50%;
  }
  .text-component__block--right.text-component__block--outset {
    margin-right: -5.25em;
    margin-right: calc(-1 * var(--space-xxl));
  }
}
:root {
  --icon-xxs: 12px;
  --icon-xs: 16px;
  --icon-sm: 24px;
  --icon-md: 32px;
  --icon-lg: 48px;
  --icon-xl: 64px;
  --icon-xxl: 128px;
}
.icon {
  display: inline-block;
  color: inherit;
  fill: currentColor;
  height: 1em;
  width: 1em;
  line-height: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.icon--xxs {
  font-size: 12px;
  font-size: var(--icon-xxs);
}
.icon--xs {
  font-size: 16px;
  font-size: var(--icon-xs);
}
.icon--sm {
  font-size: 24px;
  font-size: var(--icon-sm);
}
.icon--md {
  font-size: 32px;
  font-size: var(--icon-md);
}
.icon--lg {
  font-size: 48px;
  font-size: var(--icon-lg);
}
.icon--xl {
  font-size: 64px;
  font-size: var(--icon-xl);
}
.icon--xxl {
  font-size: 128px;
  font-size: var(--icon-xxl);
}
.icon--is-spinning {
  -webkit-animation: icon-spin 1s infinite linear;
  animation: icon-spin 1s infinite linear;
}
@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.icon use {
  color: inherit;
  fill: currentColor;
}
/* .btn {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  line-height: 1;
  font-size: 1em;
  font-size: var(--btn-font-size, 1em);
  padding-top: 0.5em;
  padding-top: var(--btn-padding-y, 0.5em);
  padding-bottom: 0.5em;
  padding-bottom: var(--btn-padding-y, 0.5em);
  padding-left: 0.75em;
  padding-left: var(--btn-padding-x, 0.75em);
  padding-right: 0.75em;
  padding-right: var(--btn-padding-x, 0.75em);
  border-radius: 0.25em;
  border-radius: var(--btn-radius, 0.25em);
}
.btn--primary {
  background-color: hsl(220, 90%, 56%);
  background-color: var(--color-primary, #2a6df4);
  color: hsl(0, 0%, 100%);
  color: var(--color-white, #fff);
}
.btn--subtle {
  background-color: hsl(240, 1%, 83%);
  background-color: var(--color-contrast-low, #d3d3d4);
  color: hsl(240, 8%, 12%);
  color: var(--color-contrast-higher, #1c1c21);
}
.btn--accent {
  background-color: hsl(355, 90%, 61%);
  background-color: var(--color-accent, #f54251);
  color: hsl(0, 0%, 100%);
  color: var(--color-white, #fff);
}
.btn--disabled {
  cursor: not-allowed;
}
.btn--sm {
  font-size: 0.8em;
  font-size: var(--btn-font-size-sm, 0.8em);
}
.btn--md {
  font-size: 1.2em;
  font-size: var(--btn-font-size-md, 1.2em);
}
.btn--lg {
  font-size: 1.4em;
  font-size: var(--btn-font-size-lg, 1.4em);
}
.btn--icon {
  padding: 0.5em;
  padding: var(--btn-padding-y, 0.5em);
} */
.form-control {
  background-color: hsl(0, 0%, 100%);
  background-color: var(--color-bg, #f2f2f2);
  padding-top: 0.5em;
  padding-top: var(--form-control-padding-y, 0.5em);
  padding-bottom: 0.5em;
  padding-bottom: var(--form-control-padding-y, 0.5em);
  padding-left: 0.75em;
  padding-left: var(--form-control-padding-x, 0.75em);
  padding-right: 0.75em;
  padding-right: var(--form-control-padding-x, 0.75em);
  border-radius: 0.25em;
  border-radius: var(--form-control-radius, 0.25em);
}
.form-control::-webkit-input-placeholder {
  color: hsl(240, 1%, 48%);
  color: var(--color-contrast-medium, #79797c);
}
.form-control::-moz-placeholder {
  opacity: 1;
  color: hsl(240, 1%, 48%);
  color: var(--color-contrast-medium, #79797c);
}
.form-control:-ms-input-placeholder {
  color: hsl(240, 1%, 48%);
  color: var(--color-contrast-medium, #79797c);
}
.form-control:-moz-placeholder {
  color: hsl(240, 1%, 48%);
  color: var(--color-contrast-medium, #79797c);
}
.form-control[disabled],
.form-control[readonly] {
  cursor: not-allowed;
}
.form-legend {
  color: hsl(240, 8%, 12%);
  color: var(--color-contrast-higher, #1c1c21);
  line-height: 1.2;
  font-size: 1.2em;
  font-size: var(--text-md, 1.2em);
  margin-bottom: 0.375em;
  margin-bottom: var(--space-xxs);
}
.form-label {
  display: inline-block;
}
.form__msg-error {
  background-color: hsl(355, 90%, 61%);
  background-color: var(--color-error, #f54251);
  color: hsl(0, 0%, 100%);
  color: var(--color-white, #fff);
  font-size: 0.83333em;
  font-size: var(--text-sm, 0.833em);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0.5em;
  padding: var(--space-xs);
  margin-top: 0.75em;
  margin-top: var(--space-sm);
  border-radius: 0.25em;
  border-radius: var(--radius-md, 0.25em);
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}
.form__msg-error::before {
  content: "";
  position: absolute;
  left: 0.75em;
  left: var(--space-sm);
  top: 0;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  transform: translateY(-100%);
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-bottom-color: hsl(355, 90%, 61%);
  border-bottom-color: var(--color-error);
}
.form__msg-error--is-visible {
  position: relative;
  clip: auto;
}
.radio-list > *,
.checkbox-list > * {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: baseline;
  align-items: baseline;
  margin-bottom: 0.375em;
  margin-bottom: var(--space-xxs);
}
.radio-list > *:last-of-type,
.checkbox-list > *:last-of-type {
  margin-bottom: 0;
}
.radio-list label,
.checkbox-list label {
  line-height: 1.4;
  line-height: var(--body-line-height);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio-list input,
.checkbox-list input {
  vertical-align: top;
  margin-right: 0.25em;
  margin-right: var(--space-xxxs);
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
:root {
  --zindex-header: 2;
  --zindex-popover: 5;
  --zindex-fixed-element: 10;
  --zindex-overlay: 15;
}
@media not all and (min-width: 32rem) {
  .display\@xs {
    display: none !important;
  }
}
@media (min-width: 32rem) {
  .hide\@xs {
    display: none !important;
  }
}
@media not all and (min-width: 48rem) {
  .display\@sm {
    display: none !important;
  }
}
@media (min-width: 48rem) {
  .hide\@sm {
    display: none !important;
  }
}
@media not all and (min-width: 64rem) {
  .display\@md {
    display: none !important;
  }
}
@media (min-width: 64rem) {
  .hide\@md {
    display: none !important;
  }
}
@media not all and (min-width: 80rem) {
  .display\@lg {
    display: none !important;
  }
}
@media (min-width: 80rem) {
  .hide\@lg {
    display: none !important;
  }
}
@media not all and (min-width: 90rem) {
  .display\@xl {
    display: none !important;
  }
}
@media (min-width: 90rem) {
  .hide\@xl {
    display: none !important;
  }
}
:root {
  --display: block;
}
.is-visible {
  display: block !important;
  display: var(--display) !important;
}
.is-hidden {
  display: none !important;
}
.sr-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  padding: 0;
  border: 0;
  white-space: nowrap;
}
.flex {
  display: -ms-flexbox;
  display: flex;
}
.inline-flex {
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.flex-column {
  -ms-flex-direction: column;
  flex-direction: column;
}
.flex-row {
  -ms-flex-direction: row;
  flex-direction: row;
}
.flex-center {
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
}
.justify-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.justify-end {
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.justify-center {
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.items-center {
  -ms-flex-align: center;
  align-items: center;
}
.items-start {
  -ms-flex-align: start;
  align-items: flex-start;
}
.items-end {
  -ms-flex-align: end;
  align-items: flex-end;
}
@media (min-width: 32rem) {
  .flex-wrap\@xs {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-column\@xs {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-row\@xs {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-center\@xs {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .justify-start\@xs {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-end\@xs {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-center\@xs {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-between\@xs {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .items-center\@xs {
    -ms-flex-align: center;
    align-items: center;
  }
  .items-start\@xs {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .items-end\@xs {
    -ms-flex-align: end;
    align-items: flex-end;
  }
}
@media (min-width: 48rem) {
  .flex-wrap\@sm {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-column\@sm {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-row\@sm {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-center\@sm {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .justify-start\@sm {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-end\@sm {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-center\@sm {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-between\@sm {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .items-center\@sm {
    -ms-flex-align: center;
    align-items: center;
  }
  .items-start\@sm {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .items-end\@sm {
    -ms-flex-align: end;
    align-items: flex-end;
  }
}
@media (min-width: 64rem) {
  .flex-wrap\@md {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-column\@md {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-row\@md {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-center\@md {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .justify-start\@md {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-end\@md {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-center\@md {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-between\@md {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .items-center\@md {
    -ms-flex-align: center;
    align-items: center;
  }
  .items-start\@md {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .items-end\@md {
    -ms-flex-align: end;
    align-items: flex-end;
  }
}
@media (min-width: 80rem) {
  .flex-wrap\@lg {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-column\@lg {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-row\@lg {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-center\@lg {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .justify-start\@lg {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-end\@lg {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-center\@lg {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-between\@lg {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .items-center\@lg {
    -ms-flex-align: center;
    align-items: center;
  }
  .items-start\@lg {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .items-end\@lg {
    -ms-flex-align: end;
    align-items: flex-end;
  }
}
@media (min-width: 90rem) {
  .flex-wrap\@xl {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .flex-column\@xl {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .flex-row\@xl {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .flex-center\@xl {
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .justify-start\@xl {
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .justify-end\@xl {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .justify-center\@xl {
    -ms-flex-pack: center;
    justify-content: center;
  }
  .justify-between\@xl {
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .items-center\@xl {
    -ms-flex-align: center;
    align-items: center;
  }
  .items-start\@xl {
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .items-end\@xl {
    -ms-flex-align: end;
    align-items: flex-end;
  }
}
.flex-grow {
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.flex-gap-xxxs {
  margin-bottom: -0.25em;
  margin-bottom: calc(-1 * var(--space-xxxs));
  margin-right: -0.25em;
  margin-right: calc(-1 * var(--space-xxxs));
}
.flex-gap-xxxs > * {
  margin-bottom: 0.25em;
  margin-bottom: var(--space-xxxs);
  margin-right: 0.25em;
  margin-right: var(--space-xxxs);
}
.flex-gap-xxs {
  margin-bottom: -0.375em;
  margin-bottom: calc(-1 * var(--space-xxs));
  margin-right: -0.375em;
  margin-right: calc(-1 * var(--space-xxs));
}
.flex-gap-xxs > * {
  margin-bottom: 0.375em;
  margin-bottom: var(--space-xxs);
  margin-right: 0.375em;
  margin-right: var(--space-xxs);
}
.flex-gap-xs {
  margin-bottom: -0.5em;
  margin-bottom: calc(-1 * var(--space-xs));
  margin-right: -0.5em;
  margin-right: calc(-1 * var(--space-xs));
}
.flex-gap-xs > * {
  margin-bottom: 0.5em;
  margin-bottom: var(--space-xs);
  margin-right: 0.5em;
  margin-right: var(--space-xs);
}
.flex-gap-sm {
  margin-bottom: -0.75em;
  margin-bottom: calc(-1 * var(--space-sm));
  margin-right: -0.75em;
  margin-right: calc(-1 * var(--space-sm));
}
.flex-gap-sm > * {
  margin-bottom: 0.75em;
  margin-bottom: var(--space-sm);
  margin-right: 0.75em;
  margin-right: var(--space-sm);
}
.flex-gap-md {
  margin-bottom: -1.25em;
  margin-bottom: calc(-1 * var(--space-md));
  margin-right: -1.25em;
  margin-right: calc(-1 * var(--space-md));
}
.flex-gap-md > * {
  margin-bottom: 1.25em;
  margin-bottom: var(--space-md);
  margin-right: 1.25em;
  margin-right: var(--space-md);
}
.flex-gap-lg {
  margin-bottom: -2em;
  margin-bottom: calc(-1 * var(--space-lg));
  margin-right: -2em;
  margin-right: calc(-1 * var(--space-lg));
}
.flex-gap-lg > * {
  margin-bottom: 2em;
  margin-bottom: var(--space-lg);
  margin-right: 2em;
  margin-right: var(--space-lg);
}
.flex-gap-xl {
  margin-bottom: -3.25em;
  margin-bottom: calc(-1 * var(--space-xl));
  margin-right: -3.25em;
  margin-right: calc(-1 * var(--space-xl));
}
.flex-gap-xl > * {
  margin-bottom: 3.25em;
  margin-bottom: var(--space-xl);
  margin-right: 3.25em;
  margin-right: var(--space-xl);
}
.flex-gap-xxl {
  margin-bottom: -5.25em;
  margin-bottom: calc(-1 * var(--space-xxl));
  margin-right: -5.25em;
  margin-right: calc(-1 * var(--space-xxl));
}
.flex-gap-xxl > * {
  margin-bottom: 5.25em;
  margin-bottom: var(--space-xxl);
  margin-right: 5.25em;
  margin-right: var(--space-xxl);
}
.margin-xxxxs {
  margin: 0.125em;
  margin: var(--space-xxxxs);
}
.margin-xxxs {
  margin: 0.25em;
  margin: var(--space-xxxs);
}
.margin-xxs {
  margin: 0.375em;
  margin: var(--space-xxs);
}
.margin-xs {
  margin: 0.5em;
  margin: var(--space-xs);
}
.margin-sm {
  margin: 0.75em;
  margin: var(--space-sm);
}
.margin-md {
  margin: 1.25em;
  margin: var(--space-md);
}
.margin-lg {
  margin: 2em;
  margin: var(--space-lg);
}
.margin-xl {
  margin: 3.25em;
  margin: var(--space-xl);
}
.margin-xxl {
  margin: 5.25em;
  margin: var(--space-xxl);
}
.margin-xxxl {
  margin: 8.5em;
  margin: var(--space-xxxl);
}
.margin-xxxxl {
  margin: 13.75em;
  margin: var(--space-xxxxl);
}
.margin-auto {
  margin: auto;
}
.margin-top-xxxxs {
  margin-top: 0.125em;
  margin-top: var(--space-xxxxs);
}
.margin-top-xxxs {
  margin-top: 0.25em;
  margin-top: var(--space-xxxs);
}
.margin-top-xxs {
  margin-top: 0.375em;
  margin-top: var(--space-xxs);
}
.margin-top-xs {
  margin-top: 0.5em;
  margin-top: var(--space-xs);
}
.margin-top-sm {
  margin-top: 0.75em;
  margin-top: var(--space-sm);
}
.margin-top-md {
  margin-top: 1.25em;
  margin-top: var(--space-md);
}
.margin-top-lg {
  margin-top: 2em;
  margin-top: var(--space-lg);
}
.margin-top-xl {
  margin-top: 3.25em;
  margin-top: var(--space-xl);
}
.margin-top-xxl {
  margin-top: 5.25em;
  margin-top: var(--space-xxl);
}
.margin-top-xxxl {
  margin-top: 8.5em;
  margin-top: var(--space-xxxl);
}
.margin-top-xxxxl {
  margin-top: 13.75em;
  margin-top: var(--space-xxxxl);
}
.margin-top-auto {
  margin-top: auto;
}
.margin-bottom-xxxxs {
  margin-bottom: 0.125em;
  margin-bottom: var(--space-xxxxs);
}
.margin-bottom-xxxs {
  margin-bottom: 0.25em;
  margin-bottom: var(--space-xxxs);
}
.margin-bottom-xxs {
  margin-bottom: 0.375em;
  margin-bottom: var(--space-xxs);
}
.margin-bottom-xs {
  margin-bottom: 0.5em;
  margin-bottom: var(--space-xs);
}
.margin-bottom-sm {
  margin-bottom: 0.75em;
  margin-bottom: var(--space-sm);
}
.margin-bottom-md {
  margin-bottom: 1.25em;
  margin-bottom: var(--space-md);
}
.margin-bottom-lg {
  margin-bottom: 2em;
  margin-bottom: var(--space-lg);
}
.margin-bottom-xl {
  margin-bottom: 3.25em;
  margin-bottom: var(--space-xl);
}
.margin-bottom-xxl {
  margin-bottom: 5.25em;
  margin-bottom: var(--space-xxl);
}
.margin-bottom-xxxl {
  margin-bottom: 8.5em;
  margin-bottom: var(--space-xxxl);
}
.margin-bottom-xxxxl {
  margin-bottom: 13.75em;
  margin-bottom: var(--space-xxxxl);
}
.margin-bottom-auto {
  margin-bottom: auto;
}
.margin-right-xxxxs {
  margin-right: 0.125em;
  margin-right: var(--space-xxxxs);
}
.margin-right-xxxs {
  margin-right: 0.25em;
  margin-right: var(--space-xxxs);
}
.margin-right-xxs {
  margin-right: 0.375em;
  margin-right: var(--space-xxs);
}
.margin-right-xs {
  margin-right: 0.5em;
  margin-right: var(--space-xs);
}
.margin-right-sm {
  margin-right: 0.75em;
  margin-right: var(--space-sm);
}
.margin-right-md {
  margin-right: 1.25em;
  margin-right: var(--space-md);
}
.margin-right-lg {
  margin-right: 2em;
  margin-right: var(--space-lg);
}
.margin-right-xl {
  margin-right: 3.25em;
  margin-right: var(--space-xl);
}
.margin-right-xxl {
  margin-right: 5.25em;
  margin-right: var(--space-xxl);
}
.margin-right-xxxl {
  margin-right: 8.5em;
  margin-right: var(--space-xxxl);
}
.margin-right-xxxxl {
  margin-right: 13.75em;
  margin-right: var(--space-xxxxl);
}
.margin-right-auto {
  margin-right: auto;
}
.margin-left-xxxxs {
  margin-left: 0.125em;
  margin-left: var(--space-xxxxs);
}
.margin-left-xxxs {
  margin-left: 0.25em;
  margin-left: var(--space-xxxs);
}
.margin-left-xxs {
  margin-left: 0.375em;
  margin-left: var(--space-xxs);
}
.margin-left-xs {
  margin-left: 0.5em;
  margin-left: var(--space-xs);
}
.margin-left-sm {
  margin-left: 0.75em;
  margin-left: var(--space-sm);
}
.margin-left-md {
  margin-left: 1.25em;
  margin-left: var(--space-md);
}
.margin-left-lg {
  margin-left: 2em;
  margin-left: var(--space-lg);
}
.margin-left-xl {
  margin-left: 3.25em;
  margin-left: var(--space-xl);
}
.margin-left-xxl {
  margin-left: 5.25em;
  margin-left: var(--space-xxl);
}
.margin-left-xxxl {
  margin-left: 8.5em;
  margin-left: var(--space-xxxl);
}
.margin-left-xxxxl {
  margin-left: 13.75em;
  margin-left: var(--space-xxxxl);
}
.margin-left-auto {
  margin-left: auto;
}
.margin-x-xxxxs {
  margin-left: 0.125em;
  margin-left: var(--space-xxxxs);
  margin-right: 0.125em;
  margin-right: var(--space-xxxxs);
}
.margin-x-xxxs {
  margin-left: 0.25em;
  margin-left: var(--space-xxxs);
  margin-right: 0.25em;
  margin-right: var(--space-xxxs);
}
.margin-x-xxs {
  margin-left: 0.375em;
  margin-left: var(--space-xxs);
  margin-right: 0.375em;
  margin-right: var(--space-xxs);
}
.margin-x-xs {
  margin-left: 0.5em;
  margin-left: var(--space-xs);
  margin-right: 0.5em;
  margin-right: var(--space-xs);
}
.margin-x-sm {
  margin-left: 0.75em;
  margin-left: var(--space-sm);
  margin-right: 0.75em;
  margin-right: var(--space-sm);
}
.margin-x-md {
  margin-left: 1.25em;
  margin-left: var(--space-md);
  margin-right: 1.25em;
  margin-right: var(--space-md);
}
.margin-x-lg {
  margin-left: 2em;
  margin-left: var(--space-lg);
  margin-right: 2em;
  margin-right: var(--space-lg);
}
.margin-x-xl {
  margin-left: 3.25em;
  margin-left: var(--space-xl);
  margin-right: 3.25em;
  margin-right: var(--space-xl);
}
.margin-x-xxl {
  margin-left: 5.25em;
  margin-left: var(--space-xxl);
  margin-right: 5.25em;
  margin-right: var(--space-xxl);
}
.margin-x-xxxl {
  margin-left: 8.5em;
  margin-left: var(--space-xxxl);
  margin-right: 8.5em;
  margin-right: var(--space-xxxl);
}
.margin-x-xxxxl {
  margin-left: 13.75em;
  margin-left: var(--space-xxxxl);
  margin-right: 13.75em;
  margin-right: var(--space-xxxxl);
}
.margin-x-auto {
  margin-left: auto;
  margin-right: auto;
}
.margin-y-xxxxs {
  margin-top: 0.125em;
  margin-top: var(--space-xxxxs);
  margin-bottom: 0.125em;
  margin-bottom: var(--space-xxxxs);
}
.margin-y-xxxs {
  margin-top: 0.25em;
  margin-top: var(--space-xxxs);
  margin-bottom: 0.25em;
  margin-bottom: var(--space-xxxs);
}
.margin-y-xxs {
  margin-top: 0.375em;
  margin-top: var(--space-xxs);
  margin-bottom: 0.375em;
  margin-bottom: var(--space-xxs);
}
.margin-y-xs {
  margin-top: 0.5em;
  margin-top: var(--space-xs);
  margin-bottom: 0.5em;
  margin-bottom: var(--space-xs);
}
.margin-y-sm {
  margin-top: 0.75em;
  margin-top: var(--space-sm);
  margin-bottom: 0.75em;
  margin-bottom: var(--space-sm);
}
.margin-y-md {
  margin-top: 1.25em;
  margin-top: var(--space-md);
  margin-bottom: 1.25em;
  margin-bottom: var(--space-md);
}
.margin-y-lg {
  margin-top: 2em;
  margin-top: var(--space-lg);
  margin-bottom: 2em;
  margin-bottom: var(--space-lg);
}
.margin-y-xl {
  margin-top: 3.25em;
  margin-top: var(--space-xl);
  margin-bottom: 3.25em;
  margin-bottom: var(--space-xl);
}
.margin-y-xxl {
  margin-top: 5.25em;
  margin-top: var(--space-xxl);
  margin-bottom: 5.25em;
  margin-bottom: var(--space-xxl);
}
.margin-y-xxxl {
  margin-top: 8.5em;
  margin-top: var(--space-xxxl);
  margin-bottom: 8.5em;
  margin-bottom: var(--space-xxxl);
}
.margin-y-xxxxl {
  margin-top: 13.75em;
  margin-top: var(--space-xxxxl);
  margin-bottom: 13.75em;
  margin-bottom: var(--space-xxxxl);
}
.margin-y-auto {
  margin-top: auto;
  margin-bottom: auto;
}
@media not all and (min-width: 32rem) {
  .has-margin\@xs {
    margin: 0 !important;
  }
}
@media not all and (min-width: 48rem) {
  .has-margin\@sm {
    margin: 0 !important;
  }
}
@media not all and (min-width: 64rem) {
  .has-margin\@md {
    margin: 0 !important;
  }
}
@media not all and (min-width: 80rem) {
  .has-margin\@lg {
    margin: 0 !important;
  }
}
@media not all and (min-width: 90rem) {
  .has-margin\@xl {
    margin: 0 !important;
  }
}
.padding-md {
  padding: 1.25em;
  padding: var(--space-md);
}
.padding-xxxxs {
  padding: 0.125em;
  padding: var(--space-xxxxs);
}
.padding-xxxs {
  padding: 0.25em;
  padding: var(--space-xxxs);
}
.padding-xxs {
  padding: 0.375em;
  padding: var(--space-xxs);
}
.padding-xs {
  padding: 0.5em;
  padding: var(--space-xs);
}
.padding-sm {
  padding: 0.75em;
  padding: var(--space-sm);
}
.padding-lg {
  padding: 2em;
  padding: var(--space-lg);
}
.padding-xl {
  padding: 3.25em;
  padding: var(--space-xl);
}
.padding-xxl {
  padding: 5.25em;
  padding: var(--space-xxl);
}
.padding-xxxl {
  padding: 8.5em;
  padding: var(--space-xxxl);
}
.padding-xxxxl {
  padding: 13.75em;
  padding: var(--space-xxxxl);
}
.padding-component {
  padding: 1.25em;
  padding: var(--component-padding);
}
.padding-top-md {
  padding-top: 1.25em;
  padding-top: var(--space-md);
}
.padding-top-xxxxs {
  padding-top: 0.125em;
  padding-top: var(--space-xxxxs);
}
.padding-top-xxxs {
  padding-top: 0.25em;
  padding-top: var(--space-xxxs);
}
.padding-top-xxs {
  padding-top: 0.375em;
  padding-top: var(--space-xxs);
}
.padding-top-xs {
  padding-top: 0.5em;
  padding-top: var(--space-xs);
}
.padding-top-sm {
  padding-top: 0.75em;
  padding-top: var(--space-sm);
}
.padding-top-lg {
  padding-top: 2em;
  padding-top: var(--space-lg);
}
.padding-top-xl {
  padding-top: 3.25em;
  padding-top: var(--space-xl);
}
.padding-top-xxl {
  padding-top: 5.25em;
  padding-top: var(--space-xxl);
}
.padding-top-xxxl {
  padding-top: 8.5em;
  padding-top: var(--space-xxxl);
}
.padding-top-xxxxl {
  padding-top: 13.75em;
  padding-top: var(--space-xxxxl);
}
.padding-top-component {
  padding-top: 1.25em;
  padding-top: var(--component-padding);
}
.padding-bottom-md {
  padding-bottom: 1.25em;
  padding-bottom: var(--space-md);
}
.padding-bottom-xxxxs {
  padding-bottom: 0.125em;
  padding-bottom: var(--space-xxxxs);
}
.padding-bottom-xxxs {
  padding-bottom: 0.25em;
  padding-bottom: var(--space-xxxs);
}
.padding-bottom-xxs {
  padding-bottom: 0.375em;
  padding-bottom: var(--space-xxs);
}
.padding-bottom-xs {
  padding-bottom: 0.5em;
  padding-bottom: var(--space-xs);
}
.padding-bottom-sm {
  padding-bottom: 0.75em;
  padding-bottom: var(--space-sm);
}
.padding-bottom-lg {
  padding-bottom: 2em;
  padding-bottom: var(--space-lg);
}
.padding-bottom-xl {
  padding-bottom: 3.25em;
  padding-bottom: var(--space-xl);
}
.padding-bottom-xxl {
  padding-bottom: 5.25em;
  padding-bottom: var(--space-xxl);
}
.padding-bottom-xxxl {
  padding-bottom: 8.5em;
  padding-bottom: var(--space-xxxl);
}
.padding-bottom-xxxxl {
  padding-bottom: 13.75em;
  padding-bottom: var(--space-xxxxl);
}
.padding-bottom-component {
  padding-bottom: 1.25em;
  padding-bottom: var(--component-padding);
}
.padding-right-md {
  padding-right: 1.25em;
  padding-right: var(--space-md);
}
.padding-right-xxxxs {
  padding-right: 0.125em;
  padding-right: var(--space-xxxxs);
}
.padding-right-xxxs {
  padding-right: 0.25em;
  padding-right: var(--space-xxxs);
}
.padding-right-xxs {
  padding-right: 0.375em;
  padding-right: var(--space-xxs);
}
.padding-right-xs {
  padding-right: 0.5em;
  padding-right: var(--space-xs);
}
.padding-right-sm {
  padding-right: 0.75em;
  padding-right: var(--space-sm);
}
.padding-right-lg {
  padding-right: 2em;
  padding-right: var(--space-lg);
}
.padding-right-xl {
  padding-right: 3.25em;
  padding-right: var(--space-xl);
}
.padding-right-xxl {
  padding-right: 5.25em;
  padding-right: var(--space-xxl);
}
.padding-right-xxxl {
  padding-right: 8.5em;
  padding-right: var(--space-xxxl);
}
.padding-right-xxxxl {
  padding-right: 13.75em;
  padding-right: var(--space-xxxxl);
}
.padding-right-component {
  padding-right: 1.25em;
  padding-right: var(--component-padding);
}
.padding-left-md {
  padding-left: 1.25em;
  padding-left: var(--space-md);
}
.padding-left-xxxxs {
  padding-left: 0.125em;
  padding-left: var(--space-xxxxs);
}
.padding-left-xxxs {
  padding-left: 0.25em;
  padding-left: var(--space-xxxs);
}
.padding-left-xxs {
  padding-left: 0.375em;
  padding-left: var(--space-xxs);
}
.padding-left-xs {
  padding-left: 0.5em;
  padding-left: var(--space-xs);
}
.padding-left-sm {
  padding-left: 0.75em;
  padding-left: var(--space-sm);
}
.padding-left-lg {
  padding-left: 2em;
  padding-left: var(--space-lg);
}
.padding-left-xl {
  padding-left: 3.25em;
  padding-left: var(--space-xl);
}
.padding-left-xxl {
  padding-left: 5.25em;
  padding-left: var(--space-xxl);
}
.padding-left-xxxl {
  padding-left: 8.5em;
  padding-left: var(--space-xxxl);
}
.padding-left-xxxxl {
  padding-left: 13.75em;
  padding-left: var(--space-xxxxl);
}
.padding-left-component {
  padding-left: 1.25em;
  padding-left: var(--component-padding);
}
.padding-x-md {
  padding-left: 1.25em;
  padding-left: var(--space-md);
  padding-right: 1.25em;
  padding-right: var(--space-md);
}
.padding-x-xxxxs {
  padding-left: 0.125em;
  padding-left: var(--space-xxxxs);
  padding-right: 0.125em;
  padding-right: var(--space-xxxxs);
}
.padding-x-xxxs {
  padding-left: 0.25em;
  padding-left: var(--space-xxxs);
  padding-right: 0.25em;
  padding-right: var(--space-xxxs);
}
.padding-x-xxs {
  padding-left: 0.375em;
  padding-left: var(--space-xxs);
  padding-right: 0.375em;
  padding-right: var(--space-xxs);
}
.padding-x-xs {
  padding-left: 0.5em;
  padding-left: var(--space-xs);
  padding-right: 0.5em;
  padding-right: var(--space-xs);
}
.padding-x-sm {
  padding-left: 0.75em;
  padding-left: var(--space-sm);
  padding-right: 0.75em;
  padding-right: var(--space-sm);
}
.padding-x-lg {
  padding-left: 2em;
  padding-left: var(--space-lg);
  padding-right: 2em;
  padding-right: var(--space-lg);
}
.padding-x-xl {
  padding-left: 3.25em;
  padding-left: var(--space-xl);
  padding-right: 3.25em;
  padding-right: var(--space-xl);
}
.padding-x-xxl {
  padding-left: 5.25em;
  padding-left: var(--space-xxl);
  padding-right: 5.25em;
  padding-right: var(--space-xxl);
}
.padding-x-xxxl {
  padding-left: 8.5em;
  padding-left: var(--space-xxxl);
  padding-right: 8.5em;
  padding-right: var(--space-xxxl);
}
.padding-x-xxxxl {
  padding-left: 13.75em;
  padding-left: var(--space-xxxxl);
  padding-right: 13.75em;
  padding-right: var(--space-xxxxl);
}
.padding-x-component {
  padding-left: 1.25em;
  padding-left: var(--component-padding);
  padding-right: 1.25em;
  padding-right: var(--component-padding);
}
.padding-y-md {
  padding-top: 1.25em;
  padding-top: var(--space-md);
  padding-bottom: 1.25em;
  padding-bottom: var(--space-md);
}
.padding-y-xxxxs {
  padding-top: 0.125em;
  padding-top: var(--space-xxxxs);
  padding-bottom: 0.125em;
  padding-bottom: var(--space-xxxxs);
}
.padding-y-xxxs {
  padding-top: 0.25em;
  padding-top: var(--space-xxxs);
  padding-bottom: 0.25em;
  padding-bottom: var(--space-xxxs);
}
.padding-y-xxs {
  padding-top: 0.375em;
  padding-top: var(--space-xxs);
  padding-bottom: 0.375em;
  padding-bottom: var(--space-xxs);
}
.padding-y-xs {
  padding-top: 0.5em;
  padding-top: var(--space-xs);
  padding-bottom: 0.5em;
  padding-bottom: var(--space-xs);
}
.padding-y-sm {
  padding-top: 0.75em;
  padding-top: var(--space-sm);
  padding-bottom: 0.75em;
  padding-bottom: var(--space-sm);
}
.padding-y-lg {
  padding-top: 2em;
  padding-top: var(--space-lg);
  padding-bottom: 2em;
  padding-bottom: var(--space-lg);
}
.padding-y-xl {
  padding-top: 3.25em;
  padding-top: var(--space-xl);
  padding-bottom: 3.25em;
  padding-bottom: var(--space-xl);
}
.padding-y-xxl {
  padding-top: 5.25em;
  padding-top: var(--space-xxl);
  padding-bottom: 5.25em;
  padding-bottom: var(--space-xxl);
}
.padding-y-xxxl {
  padding-top: 8.5em;
  padding-top: var(--space-xxxl);
  padding-bottom: 8.5em;
  padding-bottom: var(--space-xxxl);
}
.padding-y-xxxxl {
  padding-top: 13.75em;
  padding-top: var(--space-xxxxl);
  padding-bottom: 13.75em;
  padding-bottom: var(--space-xxxxl);
}
.padding-y-component {
  padding-top: 1.25em;
  padding-top: var(--component-padding);
  padding-bottom: 1.25em;
  padding-bottom: var(--component-padding);
}
@media not all and (min-width: 32rem) {
  .has-padding\@xs {
    padding: 0 !important;
  }
}
@media not all and (min-width: 48rem) {
  .has-padding\@sm {
    padding: 0 !important;
  }
}
@media not all and (min-width: 64rem) {
  .has-padding\@md {
    padding: 0 !important;
  }
}
@media not all and (min-width: 80rem) {
  .has-padding\@lg {
    padding: 0 !important;
  }
}
@media not all and (min-width: 90rem) {
  .has-padding\@xl {
    padding: 0 !important;
  }
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-replace {
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
@media (min-width: 32rem) {
  .text-center\@xs {
    text-align: center;
  }
  .text-left\@xs {
    text-align: left;
  }
  .text-right\@xs {
    text-align: right;
  }
}
@media (min-width: 48rem) {
  .text-center\@sm {
    text-align: center;
  }
  .text-left\@sm {
    text-align: left;
  }
  .text-right\@sm {
    text-align: right;
  }
}
@media (min-width: 64rem) {
  .text-center\@md {
    text-align: center;
  }
  .text-left\@md {
    text-align: left;
  }
  .text-right\@md {
    text-align: right;
  }
}
@media (min-width: 80rem) {
  .text-center\@lg {
    text-align: center;
  }
  .text-left\@lg {
    text-align: left;
  }
  .text-right\@lg {
    text-align: right;
  }
}
@media (min-width: 90rem) {
  .text-center\@xl {
    text-align: center;
  }
  .text-left\@xl {
    text-align: left;
  }
  .text-right\@xl {
    text-align: right;
  }
}
.color-inherit {
  color: inherit;
}
.color-contrast-medium {
  color: hsl(240, 1%, 48%);
  color: var(--color-contrast-medium, #79797c);
}
.color-contrast-high {
  color: hsl(240, 4%, 20%);
  color: var(--color-contrast-high, #313135);
}
.color-contrast-higher {
  color: hsl(240, 8%, 12%);
  color: var(--color-contrast-higher, #1c1c21);
}
.color-primary {
  color: hsl(220, 90%, 56%);
  color: var(--color-primary, #2a6df4);
}
.color-accent {
  color: hsl(355, 90%, 61%);
  color: var(--color-accent, #f54251);
}
.color-success {
  color: hsl(94, 48%, 56%);
  color: var(--color-success, #88c559);
}
.color-warning {
  color: hsl(46, 100%, 61%);
  color: var(--color-warning, #ffd138);
}
.color-error {
  color: hsl(355, 90%, 61%);
  color: var(--color-error, #f54251);
}
.width-100\% {
  width: 100%;
}
.height-100\% {
  height: 100%;
}
.media-wrapper {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
}
.media-wrapper iframe,
.media-wrapper video,
.media-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.media-wrapper video,
.media-wrapper img {
  -o-object-fit: cover;
  object-fit: cover;
}
.media-wrapper--4\:3 {
  padding-bottom: 75%;
}
:root,
[data-theme="default"] {
  --color-primary-darker: hsl(220, 90%, 36%);
  --color-primary-darker-h: 220;
  --color-primary-darker-s: 90%;
  --color-primary-darker-l: 36%;
  --color-primary-dark: hsl(220, 90%, 46%);
  --color-primary-dark-h: 220;
  --color-primary-dark-s: 90%;
  --color-primary-dark-l: 46%;
  --color-primary: hsl(220, 90%, 56%);
  --color-primary-h: 220;
  --color-primary-s: 90%;
  --color-primary-l: 56%;
  --color-primary-light: hsl(220, 90%, 66%);
  --color-primary-light-h: 220;
  --color-primary-light-s: 90%;
  --color-primary-light-l: 66%;
  --color-primary-lighter: hsl(220, 90%, 76%);
  --color-primary-lighter-h: 220;
  --color-primary-lighter-s: 90%;
  --color-primary-lighter-l: 76%;
  --color-accent-darker: hsl(355, 90%, 41%);
  --color-accent-darker-h: 355;
  --color-accent-darker-s: 90%;
  --color-accent-darker-l: 41%;
  --color-accent-dark: hsl(355, 90%, 51%);
  --color-accent-dark-h: 355;
  --color-accent-dark-s: 90%;
  --color-accent-dark-l: 51%;
  --color-accent: hsl(355, 90%, 61%);
  --color-accent-h: 355;
  --color-accent-s: 90%;
  --color-accent-l: 61%;
  --color-accent-light: hsl(355, 90%, 71%);
  --color-accent-light-h: 355;
  --color-accent-light-s: 90%;
  --color-accent-light-l: 71%;
  --color-accent-lighter: hsl(355, 90%, 81%);
  --color-accent-lighter-h: 355;
  --color-accent-lighter-s: 90%;
  --color-accent-lighter-l: 81%;
  --color-black: hsl(240, 8%, 12%);
  --color-black-h: 240;
  --color-black-s: 8%;
  --color-black-l: 12%;
  --color-white: hsl(0, 0%, 100%);
  --color-white-h: 0;
  --color-white-s: 0%;
  --color-white-l: 100%;
  --color-success-darker: hsl(94, 48%, 36%);
  --color-success-darker-h: 94;
  --color-success-darker-s: 48%;
  --color-success-darker-l: 36%;
  --color-success-dark: hsl(94, 48%, 46%);
  --color-success-dark-h: 94;
  --color-success-dark-s: 48%;
  --color-success-dark-l: 46%;
  --color-success: hsl(94, 48%, 56%);
  --color-success-h: 94;
  --color-success-s: 48%;
  --color-success-l: 56%;
  --color-success-light: hsl(94, 48%, 66%);
  --color-success-light-h: 94;
  --color-success-light-s: 48%;
  --color-success-light-l: 66%;
  --color-success-lighter: hsl(94, 48%, 76%);
  --color-success-lighter-h: 94;
  --color-success-lighter-s: 48%;
  --color-success-lighter-l: 76%;
  --color-error-darker: hsl(355, 90%, 41%);
  --color-error-darker-h: 355;
  --color-error-darker-s: 90%;
  --color-error-darker-l: 41%;
  --color-error-dark: hsl(355, 90%, 51%);
  --color-error-dark-h: 355;
  --color-error-dark-s: 90%;
  --color-error-dark-l: 51%;
  --color-error: hsl(355, 90%, 61%);
  --color-error-h: 355;
  --color-error-s: 90%;
  --color-error-l: 61%;
  --color-error-light: hsl(355, 90%, 71%);
  --color-error-light-h: 355;
  --color-error-light-s: 90%;
  --color-error-light-l: 71%;
  --color-error-lighter: hsl(355, 90%, 81%);
  --color-error-lighter-h: 355;
  --color-error-lighter-s: 90%;
  --color-error-lighter-l: 81%;
  --color-warning-darker: hsl(46, 100%, 41%);
  --color-warning-darker-h: 46;
  --color-warning-darker-s: 100%;
  --color-warning-darker-l: 41%;
  --color-warning-dark: hsl(46, 100%, 51%);
  --color-warning-dark-h: 46;
  --color-warning-dark-s: 100%;
  --color-warning-dark-l: 51%;
  --color-warning: hsl(46, 100%, 61%);
  --color-warning-h: 46;
  --color-warning-s: 100%;
  --color-warning-l: 61%;
  --color-warning-light: hsl(46, 100%, 71%);
  --color-warning-light-h: 46;
  --color-warning-light-s: 100%;
  --color-warning-light-l: 71%;
  --color-warning-lighter: hsl(46, 100%, 81%);
  --color-warning-lighter-h: 46;
  --color-warning-lighter-s: 100%;
  --color-warning-lighter-l: 81%;
  --color-bg: hsl(0, 0%, 100%);
  --color-bg-h: 0;
  --color-bg-s: 0%;
  --color-bg-l: 100%;
  --color-contrast-lower: hsl(0, 0%, 95%);
  --color-contrast-lower-h: 0;
  --color-contrast-lower-s: 0%;
  --color-contrast-lower-l: 95%;
  --color-contrast-low: hsl(240, 1%, 83%);
  --color-contrast-low-h: 240;
  --color-contrast-low-s: 1%;
  --color-contrast-low-l: 83%;
  --color-contrast-medium: hsl(240, 1%, 48%);
  --color-contrast-medium-h: 240;
  --color-contrast-medium-s: 1%;
  --color-contrast-medium-l: 48%;
  --color-contrast-high: hsl(240, 4%, 20%);
  --color-contrast-high-h: 240;
  --color-contrast-high-s: 4%;
  --color-contrast-high-l: 20%;
  --color-contrast-higher: hsl(240, 8%, 12%);
  --color-contrast-higher-h: 240;
  --color-contrast-higher-s: 8%;
  --color-contrast-higher-l: 12%;
}
@supports (--css: variables) {
  @media (min-width: 64rem) {
    :root {
      --space-unit: 1.25em;
    }
  }
}
:root {
  --radius: 0.25em;
}
:root {
  --font-primary: sans-serif;
  --text-base-size: 1em;
  --text-scale-ratio: 1.2;
  --text-xs: calc(1em / var(--text-scale-ratio) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * var(--text-scale-ratio));
  --text-md: calc(
    var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));
  --body-line-height: 1.4;
  --heading-line-height: 1.2;
  --font-primary-capital-letter: 1;
}
@supports (--css: variables) {
  @media (min-width: 64rem) {
    :root {
      --text-base-size: 1.25em;
      --text-scale-ratio: 1.25;
    }
  }
}
mark {
  background-color: hsla(355, 90%, 61%, 0.2);
  background-color: hsla(
    var(--color-accent-h),
    var(--color-accent-s),
    var(--color-accent-l),
    0.2
  );
  color: inherit;
}
.text-component {
  --line-height-multiplier: 1;
  --text-vspace-multiplier: 1;
}
.text-component blockquote {
  padding-left: 1em;
  border-left: 4px solid hsl(240, 1%, 83%);
  border-left: 4px solid var(--color-contrast-low);
}
.text-component hr {
  background: hsl(240, 1%, 83%);
  background: var(--color-contrast-low);
  height: 1px;
}
.text-component figcaption {
  font-size: 0.83333em;
  font-size: var(--text-sm);
  color: hsl(240, 1%, 48%);
  color: var(--color-contrast-medium);
}
.article.text-component {
  --line-height-multiplier: 1.13;
  --text-vspace-multiplier: 1.2;
}
:root {
  --btn-font-size: 1em;
  --btn-font-size-sm: calc(var(--btn-font-size) - 0.2em);
  --btn-font-size-md: calc(var(--btn-font-size) + 0.2em);
  --btn-font-size-lg: calc(var(--btn-font-size) + 0.4em);
  --btn-radius: 0.25em;
  --btn-padding-x: var(--space-sm);
  --btn-padding-y: var(--space-xs);
}
.btn {
  --color-shadow: hsla(240, 8%, 12%, 0.15);
  --color-shadow: hsla(
    var(--color-black-h),
    var(--color-black-s),
    var(--color-black-l),
    0.15
  );
  box-shadow: 0 4px 16px hsla(240, 8%, 12%, 0.15);
  box-shadow: 0 4px 16px
    hsla(var(--color-black-h), var(--color-black-s), var(--color-black-l), 0.15);
  cursor: pointer;
}
.btn--primary {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn--accent {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn--disabled {
  opacity: 0.6;
}
:root {
  --form-control-padding-x: var(--space-sm);
  --form-control-padding-y: var(--space-xs);
  --form-control-radius: 0.25em;
}
.form-control {
  border: 2px solid hsl(240, 1%, 83%);
  border: 2px solid var(--color-contrast-low);
}
.form-control:focus {
  outline: none;
  border-color: hsl(220, 90%, 56%);
  border-color: var(--color-primary);
  --color-shadow: hsla(220, 90%, 56%, 0.2);
  --color-shadow: hsla(
    var(--color-primary-h),
    var(--color-primary-s),
    var(--color-primary-l),
    0.2
  );
  box-shadow: undefined;
  box-shadow: 0 0 0 3px var(--color-shadow);
}
.form-control:focus:focus {
  box-shadow: 0 0 0 3px hsla(220, 90%, 56%, 0.2);
  box-shadow: 0 0 0 3px var(--color-shadow);
}
.form-control[aria-invalid="true"] {
  border-color: hsl(355, 90%, 61%);
  border-color: var(--color-error);
}
.form-control[aria-invalid="true"]:focus {
  --color-shadow: hsla(355, 90%, 61%, 0.2);
  --color-shadow: hsla(
    var(--color-error-h),
    var(--color-error-s),
    var(--color-error-l),
    0.2
  );
  box-shadow: undefined;
  box-shadow: 0 0 0 3px var(--color-shadow);
}
.form-control[aria-invalid="true"]:focus:focus {
  box-shadow: 0 0 0 3px hsla(355, 90%, 61%, 0.2);
  box-shadow: 0 0 0 3px var(--color-shadow);
}
.form-label {
  font-size: 0.83333em;
  font-size: var(--text-sm);
}
:root {
  --cd-color-1: hsl(218, 7%, 32%);
  --cd-color-1-h: 218;
  --cd-color-1-s: 7%;
  --cd-color-1-l: 32%;
  --cd-color-2: hsl(127, 83%, 80%);
  --cd-color-2-h: 127;
  --cd-color-2-s: 83%;
  --cd-color-2-l: 80%;
  --font-primary: "Jaldi", sans-serif;
}
.cd-article-link {
  color: rgba(0, 0, 0, 0.5);
}
/* h1 {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.9);
} */
.cd-accordion {
  background: hsl(218, 7%, 32%);
  background: var(--cd-color-1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1), 0 16px 48px rgba(0, 0, 0, 0.1),
    0 24px 60px rgba(0, 0, 0, 0.1);
  box-shadow: var(--shadow-lg);
}
.cd-accordion--animated .cd-accordion__label::before {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.cd-accordion__sub {
  display: none;
  overflow: hidden;
}
.cd-accordion-auto-list {
  display: block;
  overflow: hidden;
}
.cd-accordion__sub--is-visible {
  display: block;
}
.cd-accordion__item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.cd-accordion__input {
  position: absolute;
  opacity: 0;
}
.cd-accordion__label {
  position: relative;
  display: flex;
  align-items: center;
  /* padding: 0.75em 1.25em; */
  /* padding: var(--space-sm) var(--space-md); */
  /* background: hsl(218, 7%, 32%); */
  /* background: var(--cd-color-1); */
  --color-shadow: lightness(hsl(218, 7%, 32%), 1.2);
  --color-shadow: lightness(var(--cd-color-1), 1.2);
  box-shadow: inset 0 -1px lightness(hsl(218, 7%, 32%), 1.2);
  box-shadow: inset 0 -1px var(--color-shadow);
  color: hsl(0, 0%, 100%);
  color: #000;
}
.cd-accordion__label span {
  -ms-flex-order: 3;
  order: 3;
}
/* .cd-accordion__label:hover {
    background: hsl(218, 7%, 35.2%);
    background: hsl(var(--cd-color-1-h), var(--cd-color-1-s), calc(var(--cd-color-1-l) * 1.1));
} */
.cd-accordion__label--icon-folder::before {
  color: var(--site_color);
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  margin: -5px -1px 0px 20px !important;
  font-size: 17px;
}
.asset-symble {
  padding: 0px !important;
  margin: -21px 0px -26px -3px !important;
  font-size: 16px;
  color: var(--site_color);
  height: 32px;
}
/* .cd-accordion__label--icon-folder::after {
    content: "-";
    display: block;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    margin-right: 0.25em;
    margin-right: var(--space-xxxs);
    background-position: -16px 0;
} */
.cd-accordion__label--icon-folder::before {
  -ms-flex-order: 1;
  order: 1;
}
.cd-accordion__label::after {
  -ms-flex-order: 2;
  order: 2;
}
.cd-accordion__label--icon-folder::before {
  background-position: 0 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.cd-accordion__label--icon-img::after {
  background-position: -48px 0;
}
.cd-accordion__input:checked + .cd-accordion__label::before {
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
.cd-accordion__input:checked + .cd-accordion__label::after {
  background-position: -32px 0;
}
.cd-accordion__input:checked ~ .cd-accordion__sub {
  display: block;
}
.env-tooltip-view {
  position: absolute;
}
/* .cd-accordion-enable ~ .cd-accordion__sub{
    display: block;
} */

.cd-accordion__sub--l1 .cd-accordion__label {
  /* background: hsl(218, 7%, 20.8%); */
  /* background: hsl(var(--cd-color-1-h), var(--cd-color-1-s), calc(var(--cd-color-1-l) * 0.65)); */
  --color-shadow: lightness(hsl(218, 7%, 32%), 0.85);
  --color-shadow: lightness(var(--cd-color-1), 0.85);
  box-shadow: inset 0 -1px lightness(hsl(218, 7%, 32%), 0.85);
  box-shadow: inset 0 -1px var(--color-shadow);
  padding-left: calc(1.25em + 16px);
  padding-left: calc(var(--space-md) + 16px);
}
.cd-accordion__sub--l1 {
  padding: 0px !important;
}
/* .cd-accordion__sub--l1 .cd-accordion__label:hover {
    background: hsl(218, 7%, 24%);
    background: hsl(var(--cd-color-1-h), var(--cd-color-1-s), calc(var(--cd-color-1-l) * 0.75));
} */
.cd-accordion__item:last-child .cd-accordion__label {
  box-shadow: none;
}
/* .cd-accordion__sub--l2 .cd-accordion__label {
    padding-left: calc(1.5em + 32px);
    padding-left: calc(var(--space-md) + var(--space-xxxs) + 32px);
}
.cd-accordion__sub--l3 .cd-accordion__label {
    padding-left: calc(1.5em + 48px);
    padding-left: calc(var(--space-md) + var(--space-xxxs) + 48px);
} */
.cd-accordion__sub--l2 {
  padding: 0px !important;
}
.plat-table-assets-th {
  border: none !important;
  width: 25% !important;
  margin-left: 8px !important;
}
.plat-table-assets-td {
  border: none;
}
/* .plat-assets-spend-body {
    position: relative;
} */
.cd-accordion--animated {
  /* position: absolute; */
  margin-left: -49px;
  margin-top: -13px;
  /* height: 429px;
    overflow-y: scroll; */
}
.asset-left-title {
  width: 17% !important;
}
.asset-left-title-body {
  width: 15.5% !important;
}
.asset-body-content {
  width: 26.3% !important;
  margin-right: 0.3px;
}

.assets-table-header {
  background-color: #ecf4ff !important;
  /* border-top-left-radius: 15px !important; */
  /* border-top-right-radius: 15px !important; */
  /* padding: 11px 20px 7px 11px !important; */
  border-radius: 12px 12px 0px 0px !important;
}
.asset-table-width {
  width: 100% !important;
}
.assets-table-header-title {
  background-color: var(--site_color);
  color: #fff;
  font-size: 14px;
  /* border-radius: 4px; */
  text-align: center;
  font-weight: 500;
  width: 100%;
  /* margin-left: 39px; */
  border-radius: 12px 12px 0px 0px !important;
  padding: 3px 0px 0px 0px;
  cursor: pointer;
}
.vm-assets-table-header-title {
  background-color: var(--site_color);
  color: #fff;
  font-size: 14px;
  /* border-radius: 4px; */
  text-align: center;
  font-weight: 500;
  width: 100%;
  /* margin-left: 39px; */
  border-radius: 12px 12px 0px 0px !important;
  padding: 3px 0px 0px 0px;
  cursor: pointer;
}
.asset-spend-cloud-list-icon {
  margin: 0px 5px;
  font-size: 9px;
}
.text-danger-icon-custom {
  color: #be1e2d !important;
  font-size: 30px;
  vertical-align: text-bottom;
  margin: 0px !important;
  padding: 0px !important;
}

.asset-spend-tab-icon {
  font-size: 22px;
  font-weight: 100 !important;
}
.plat-table-environment-head {
  text-align: left;
  color: #10497e;
  font-weight: bold;
}
.assets-table-title-body {
  display: flex;
  margin: 10px;
  font-size: 13px;
  font-weight: 500;
  color: var(--site_color);
  justify-content: space-between;
}

/* .assets-table-count {
    font-size: 13px;
    font-weight: 500;
    color: var(--site_color);
} */
/* .assets-table-spend {
    font-size: 13px;
    font-weight: 500;
    color: var(--site_color);
    margin-left: 83px;
} */
.assets-table-top-header {
  font-size: 13px;
  margin-top: 3.5em;
  margin-left: 3px !important;
  color: var(--site_color);
  font-weight: 500;
}

.asset-table-body-list {
  font-size: 12px;
  width: 71.5em;
  /* border-bottom: 0.37px solid #848484; */
}
.asset-spend-border-bottom {
  border-bottom: 0.37px solid #848484;
  width: 96%;
  margin-left: -4px;
}
.environment-list-title {
  padding-left: 31px !important;
}
.environment-asset-spend-border-bottom {
  border-bottom: 0.37px solid #848484;
  width: 93.7% !important;
  margin-left: 16px;
}
.cloud-list-title {
  padding-left: 49px !important;
}
.cloud-list-icon {
  margin: -23px 0px -26px 36px !important;
}
.cloud-asset-spend-border-bottom {
  border-bottom: 0.37px solid #848484;
  width: 91.8% !important;
  margin-left: 32px;
}
.application-list-title {
  padding-left: 23px !important;
  padding-top: 6px;
}
.asset-body-content-app {
  width: 25.3% !important;
  margin-right: 0.3px;
}
.application-asset-spend-border-bottom {
  border-bottom: 0.37px solid #848484;
  width: 85%;
  margin-left: 94px;
}
.environment-list-icon {
  margin: -22px 0px -20px 17px !important;
}
/* .assets-table-body-set-vm {
    display: flex;
}
.assets-table-body-set-dbaas {
    display: flex;
}
.assets-table-body-set-others {
    display: flex;
} */

/* .assets-table-body-0-set-vm {
    background: #ecf4ff;
    padding: 10px 0px;
}
.assets-table-body-set-vm {
    display: flex;
    background: #84aee7;
    width: 205px;
}
.assets-vm-body-count {
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    padding: 0px 36px;
}
.assets-vm-body-spend {
    font-size: 13px;
    font-weight: bold;
    color: #fff;
    padding-left: 59px;
} */

.asset-table-body-main-title {
  margin-top: 17px !important;
  margin-left: -3px !important;
  font-weight: 500;
  font-size: 12.24px;
  line-height: 18px;
  white-space: pre;
}
.assets-table-body-0-set {
  background: #ecf4ff;
  padding: 8px 0px 0px 0px;
  margin: 0px 0px 0px 8px;
}
.assets-table-body-set {
  display: flex;
  /* background: #84AEE7; */
  /* width: 189px; */
  margin-top: 9px;
}
.assets-body-count {
  color: #000;
  text-align: center !important;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 18px;
}
.asset-text-center {
  text-align: right !important;
  float: none !important;
  margin-right: -3px !important;
  margin-left: 0px;
}
.assets-body-spend {
  margin-right: -10px;
  float: right;
  color: #000;
  text-align: right !important;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 18px;
  white-space: nowrap;
}
.text-success-icon-custom {
  color: #188f00;
}
.asset-spend-list-right-side {
  padding: 0px !important;
  margin: 0px !important;
}
/* .plat-table-assets-spend-thead {
    width: 905px;
} */

.assets-table-body-0-set-vm {
  background: #ecf4ff;
  padding: 10px 0px;
}
.assets-table-body-set-vm {
  display: flex;
  background: var(--site_color);
  width: 206px;
}
.assets-vm-body-count {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  padding: 0px 36px;
}
.assets-vm-body-spend {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  padding-left: 59px;
}
.assets-table-body-0-set-dbaas {
  background: #ecf4ff;
  padding: 10px 0px;
}
.assets-table-body-set-dbaas {
  display: flex;
  background: var(--site_color);
  width: 206px;
}
.assets-dbaas-body-count {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  padding: 0px 36px;
}
.assets-dbaas-body-spend {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  padding-left: 59px;
}
.assets-table-body-0-set-others {
  background: #ecf4ff;
  padding: 10px 0px;
}
.assets-table-body-set-others {
  display: flex;
  background: var(--site_color);
  width: 206px;
}
.assets-others-body-count {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  padding: 0px 36px;
}
.assets-others-body-spend {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  padding-left: 59px;
}
ul.cd-accordion__sub.cd-accordion__sub--l3 {
  margin: 0px;
  padding: 0px;
}
ul.cd-accordion__sub.cd-accordion__sub--l4 {
  margin: 0px;
  padding: 0px;
}
.plat-table-assets-th-footer-title {
  width: 17.9% !important;
}
.asset-table-down-product {
  width: 865px !important;
}
.plat-table-assets-th-footer {
  width: 25% !important;
  margin-left: 8px !important;
  margin-top: -16px !important;
}
.assets-table-footer {
  background-color: #ecf4ff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 20px 20px 0px 11px;
}
.accordion-desktop-view {
  display: block;
}
.accordion-mobile-view {
  display: none;
}
.asset-table-body-list.asset-table-down-product.search-asset-list.row {
  width: 832px !important;
}
.assets-table-body-set-search {
  display: flex;
  /* background: #84AEE7; */
  width: 189px;
  margin-top: 9px;
}

.asset-table-down-product-title {
  /* margin-left: -37px !important; */
  padding-left: 109px !important;
  margin-top: 11px !important;
  margin-right: 28px;
}

.plat-breadcrumbs {
  font-weight: 400;
  font-size: 12.24px;
  line-height: 18px;
  color: #222222;
}

/* scroll */

.asset-spend-scroll {
  width: 100%;
  height: 429px;
  overflow-y: scroll;
  margin-top: -11px;
  overflow-x: hidden;
  position: relative;
}
/* .asset-spend-scroll:hover {
    width: 100%;
    height: 429px;
    overflow-y: scroll;
    margin-top: -11px;
    overflow-x: hidden;
    position: relative;
} */
.asset-spend-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  scroll-margin-left: -100px;
}

.asset-spend-scroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 3px #D9E9FF; */
  /* background-color: #D9E9FF; */
  background-color: #fff;
}

.asset-spend-scroll::-webkit-scrollbar-thumb {
  background-color: var(--site_color);
  border-radius: 10px;
  border: 2px solid #fff;
}

/* vm details */

.vm-assets-table-title-body {
  display: flow-root;
  margin: 10px;
}
.vm-assets-table-count {
  font-size: 13px;
  font-weight: 500;
  color: var(--site_color);
  float: left;
}
.vm-assets-table-spend {
  font-size: 13px;
  font-weight: 500;
  color: var(--site_color);
  float: right;
  margin-right: 35px;
}
span.vm-asset-label-span {
  width: 100% !important;
}
.vm-asset-table-body-main-title {
  margin-top: 17px !important;
  margin-left: 0px !important;
  font-weight: 500;
  font-size: 12.24px;
  line-height: 18px;
  white-space: nowrap;
  z-index: 1;
}
.vm-assets-table-header {
  margin: 0px 10px 0px 16px;
  background-color: #ecf4ff !important;
  /* border-top-left-radius: 15px !important; */
  /* border-top-right-radius: 15px !important; */
  /* padding: 11px 20px 7px 11px !important; */
  border-radius: 12px 12px 0px 0px !important;
}
.vm-assets-table-body-set {
  margin-top: 9px;
}
.vm-assets-body-count {
  color: #000;
  text-align: right !important;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 18px;
  /* padding-left: 22px; */
  width: 10%;
}
.vm-asset-spend-border-bottom {
  border-bottom: 0.37px solid #848484;
  width: 100%;
  margin-left: 0px;
}
.vm-environment-asset-spend-border-bottom {
  border-bottom: 0.37px solid #848484;
  width: 100% !important;
  margin-left: 14px;
}
.vm-cloud-list-icon {
  margin: -23px 0px -26px 24px !important;
}
.vm-cloud-asset-spend-border-bottom {
  border-bottom: 0.37px solid #848484;
  width: 100% !important;
  margin-left: 52px;
}
.vm-asset-table-down-product-title {
  margin-left: 0px !important;
  padding-left: 99px !important;
  margin-top: 11px !important;
}
.vm-cloud-spend {
  margin-left: 34px !important;
}
.vm-plat-table-assets-spend-tfooter {
  margin-top: -14px;
}
.vm-assets-table-footer {
  background-color: #ecf4ff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 20px 20px 0px 11px;
  margin-left: 18px;
  margin-top: -13px;
}
.vm-application-asset-spend-border-bottom {
  border-bottom: 0.37px solid #848484;
  width: 100% !important;
  margin-left: 88px;
}
.plat-vm-details-full-body {
  margin-top: 60px;
  width: 96%;
}
.vm-all-environment-icon {
  margin: -21px 0px -26px -2px !important;
}
.vm-environment-list-icon {
  margin: -22px 0px -20px 9px !important;
}
.vm-text-danger-icon-custom {
  color: #be1e2d !important;
  font-size: 30px;
  vertical-align: text-bottom;
  margin: 0px !important;
  padding: 0px !important;
  padding-left: 16px !important;
}
.vm-text-success-icon-custom {
  color: #188f00;
  font-size: 30px;
  vertical-align: text-bottom;
  margin: 0px !important;
  padding: 0px !important;
  padding-left: 16px !important;
}
.vm-table-main-body {
  margin: 0px 0px 0px 34px;
  background-color: #ecf4ff !important;
}
.vm-table-body {
  padding: 10px 0px;
}
.vm-application-table {
  background: #fff;
}
.vm-application-table-body-tr {
  background-color: #fff !important;
  font-weight: 400;
  font-size: 10.24px;
  line-height: 15px;
}
.table-responsive-sm {
  padding: 10px 0px;
  background: #fff;
  border: 0.37px solid #d9e9ff;
  border-radius: 6px;
  width: 100% !important;
  display: block;
  overflow-x: auto;
}
.vm-apllication-table-head {
  font-weight: 500;
  font-size: 14.24px;
  line-height: 21px;
}
.vm-application-table-head-th {
  border-bottom: none;
  padding: 2px 16px !important;
  height: 26px;
}
.vm-application-table-head-tr {
  background: #ecf4ff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
.vm-application-table-sort-icon {
  vertical-align: middle;
  color: var(--site_color);
}
.application-first-title {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.application-last-title {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.vm-application-table-body-td {
  padding: 31px 32px 2px 13px !important;
  text-align: right;
  border-bottom: 0.37px solid #848484 !important;
}
.vm-application-table-td-left {
  text-align: left;
}
.vm-application-table-chart {
  padding: 0px !important;
}
.vm-assets-table-body-0-set {
  background: #ecf4ff;
  padding: 8px 0px 0px 0px;
  margin: 0px 0px 0px 8px;
}
.vm-assets-body-spend {
  margin-right: -10px !important;
  float: right !important;
  color: #000;
  text-align: right !important;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 18px;
  width: 100px;
}

/* scroll */
.vm-application-table-scroll thead {
  position: relative;
  display: block;
  width: 100%;
  overflow-y: scroll;
  overflow-x: auto;
}
.vm-application-table-scroll thead::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.vm-application-table-scroll thead::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px #ecf4ff;
  background-color: #ecf4ff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.vm-application-table-scroll tbody {
  display: block;
  position: relative;
  width: 100%;
  overflow-y: scroll;
  overflow-x: auto;
}

.vm-application-table-scroll tbody::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.vm-application-table-scroll tbody::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 3px #D9E9FF;
    background-color: #D9E9FF; */
  background-color: #d9e9ff;
}

.vm-application-table-scroll tbody::-webkit-scrollbar-thumb {
  background-color: var(--site_color);
  border-radius: 10px;
  border: 2px solid #d9e9ff;
}

.vm-application-table-scroll tr {
  width: 100%;
  display: flex;
}
.vm-application-table-scroll tbody::-webkit-scrollbar-button:single-button {
  background-color: #d9e9ff;
  height: 10px;
  width: 10px;
}
.vm-application-table-scroll
  tbody::-webkit-scrollbar-button:single-button:vertical:decrement {
  /* border-width: 0 5px 7px 5px;
    border-color: transparent transparent var(--site_color) transparent; */
  background-image: url("../../../assets/images/costimize/vm-table-up-arrow.png");
  background-position: calc(100% - 1px) center;
  background-size: 7px 7px;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.vm-application-table-scroll
  tbody::-webkit-scrollbar-button:single-button:vertical:increment {
  background-image: url("../../../assets/images/costimize/vm-table-down-arrow.png");
  background-position: calc(100% - 1px) center;
  background-size: 7px 7px;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
/*
.vm-application-table-scroll td,.vm-application-table-scroll th{
    flex-basis:100%;
    flex-grow:2;
    display: block;
    padding: 1rem;
    text-align:left;
} */
.vm-table-body-half-screen {
  max-height: 197px;
}

.vm-application-table-head-name {
  width: 17%;
}
.vm-application-table-head-size {
  width: 8%;
}
.vm-application-table-head-ram {
  width: 8%;
}
.vm-application-table-head-core {
  width: 9%;
}
.vm-application-table-head-storage {
  width: 11%;
}
.vm-application-table-head-utilization {
  width: 13%;
}
.vm-application-table-head-savings {
  width: 21%;
}
.vm-application-table-head-chart {
  width: 13%;
}
.vm-asset-table-body-list {
  margin-left: 0px;
}
.asset-table-down-product-title-search {
  margin-left: -89px !important;
  padding-left: 142px !important;
  margin-top: 11px !important;
}
.accordion-full-width {
  width: 100%;
}
.plat-table-spend-assets-th {
  border: none !important;
  width: 25% !important;
  margin-left: 0px !important;
}
.head-foot-title {
  margin-right: 0px;
}
/* media query */
@media only screen and (max-width: 2560px) and (min-width: 2305px) {
  .plat-table-assets-th {
    border: none !important;
    width: 22% !important;
    margin-left: 8px !important;
  }
  .asset-body-content {
    width: 22.7% !important;
    margin-right: 0.3px;
  }
  .asset-table-body-main-title {
    margin-top: 17px !important;
    margin-left: 11px !important;
    font-weight: 500;
    font-size: 12.24px;
    line-height: 18px;
    margin-right: 66px;
  }
  .asset-table-down-product-title {
    padding-left: 109px !important;
    margin-top: 11px !important;
    margin-right: 95px !important;
  }
  .asset-body-content-app {
    width: 22.2% !important;
    margin-right: 0.3px;
  }
  .asset-left-title-body {
    width: 21.2% !important;
  }
  .asset-table-body-list {
    font-size: 12px;
    width: 139.4em;
    /* border-bottom: 0.37px solid #848484; */
  }
  .asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 89.2%;
    margin-left: 9px;
  }
  .environment-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 88.3% !important;
    margin-left: 24px;
  }
  .cloud-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 87.2% !important;
    margin-left: 40px;
  }
  .plat-table-assets-th-footer-title {
    width: 22.5% !important;
  }
  .plat-table-assets-th-footer {
    width: 22% !important;
    margin-left: 8px !important;
    margin-top: -16px !important;
  }
  .assets-table-body-set {
    display: flex;
    /* background: #84AEE7; */
    /* width: 189px; */
    margin-top: 9px;
    justify-content: space-around;
  }
  .assets-body-count {
    color: #000;
    text-align: inherit !important;
    font-weight: 400;
    font-size: 12.24px;
    line-height: 18px;
    margin-left: 13px;
  }
  .assets-body-spend {
    margin-right: -3px;
    float: right;
    color: #000;
    text-align: right !important;
    font-weight: 400;
    font-size: 12.24px;
    line-height: 18px;
    white-space: nowrap;
  }
  .application-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 84.1%;
    margin-left: 94px;
  }
  .asset-body-content-app {
    width: 22.2% !important;
    margin-right: 0.3px;
  }
}

@media only screen and (max-width: 2304px) and (min-width: 2161px) {
  .head-foot-title {
    margin-right: -123px;
  }
  .plat-table-assets-th {
    border: none !important;
    width: 25.5% !important;
    margin-left: 8px !important;
  }
  .plat-table-assets-th-footer-title {
    width: 17.9% !important;
  }
  .plat-table-assets-th-footer {
    width: 25.7% !important;
    margin-left: 3px !important;
    margin-top: -16px !important;
  }
  .environment-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 94.5% !important;
    margin-left: 16px;
  }
  .cloud-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 93.4% !important;
    margin-left: 32px;
  }
  .asset-body-content-app {
    width: 25.7% !important;
    margin-right: 0.3px;
  }
  .application-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 89.3%;
    margin-left: 94px;
  }
  .assets-table-body-set {
    display: flex;
    /* background: #84AEE7; */
    /* width: 189px; */
    margin-top: 9px;
    justify-content: space-around;
  }
  .assets-body-count {
    color: #000;
    text-align: inherit !important;
    font-weight: 400;
    font-size: 12.24px;
    line-height: 18px;
    margin-left: 13px;
  }
  .assets-body-spend {
    margin-right: -3px;
    float: right;
    color: #000;
    text-align: right !important;
    font-weight: 400;
    font-size: 12.24px;
    line-height: 18px;
    white-space: nowrap;
  }
}
@media only screen and (max-width: 2160px) and (min-width: 1923px) {
  .assets-table-body-set {
    display: flex;
    /* background: #84AEE7; */
    /* width: 189px; */
    margin-top: 9px;
    justify-content: space-around;
  }
  .assets-body-count {
    color: #000;
    text-align: inherit !important;
    font-weight: 400;
    font-size: 12.24px;
    line-height: 18px;
    margin-left: 13px;
  }
  .assets-body-spend {
    margin-right: -3px;
    float: right;
    color: #000;
    text-align: right !important;
    font-weight: 400;
    font-size: 12.24px;
    line-height: 18px;
    white-space: nowrap;
  }
  .plat-table-assets-th {
    border: none !important;
    width: 25.5% !important;
    margin-left: 8px !important;
  }
  .head-foot-title {
    margin-right: -114px;
  }
  .asset-body-content-app {
    width: 25.67% !important;
    margin-right: 0.3px;
  }
  .plat-table-assets-th-footer {
    width: 25.58% !important;
    margin-left: 5px !important;
    margin-top: -16px !important;
  }
  .environment-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 94.4% !important;
    margin-left: 16px;
  }
  .cloud-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 93.2% !important;
    margin-left: 32px;
  }
  .application-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 88.8%;
    margin-left: 94px;
  }
}

@media only screen and (max-width: 1922px) and (min-width: 1682px) {
  .assets-table-body-set {
    display: flex;
    /* background: #84AEE7; */
    /* width: 189px; */
    margin-top: 9px;
    justify-content: space-around;
  }
  .assets-body-count {
    color: #000;
    text-align: inherit !important;
    font-weight: 400;
    font-size: 12.24px;
    line-height: 18px;
    margin-left: 13px;
  }
  .assets-body-spend {
    margin-right: -3px;
    float: right;
    color: #000;
    text-align: right !important;
    font-weight: 400;
    font-size: 12.24px;
    line-height: 18px;
    white-space: nowrap;
  }
  .plat-table-assets-th {
    border: none !important;
    width: 25.4% !important;
    margin-left: 8px !important;
  }
  .head-foot-title {
    margin-right: -101px;
  }
  .asset-body-content-app {
    width: 25.56% !important;
    margin-right: 0.3px;
  }
  .plat-table-assets-th-footer {
    width: 25.4% !important;
    margin-left: 6.5px !important;
    margin-top: -16px !important;
  }
  .environment-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 94.2% !important;
    margin-left: 16px;
  }
  .cloud-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 92.8% !important;
    margin-left: 32px;
  }
  .application-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 87.8%;
    margin-left: 94px;
  }
}
@media only screen and (max-width: 1681px) and (min-width: 1602px) {
  .assets-table-body-set {
    display: flex;
    /* background: #84AEE7; */
    /* width: 189px; */
    margin-top: 9px;
    justify-content: space-around;
  }
  .assets-body-count {
    color: #000;
    text-align: inherit !important;
    font-weight: 400;
    font-size: 12.24px;
    line-height: 18px;
    margin-left: 13px;
  }
  .assets-body-spend {
    margin-right: -3px;
    float: right;
    color: #000;
    text-align: right !important;
    font-weight: 400;
    font-size: 12.24px;
    line-height: 18px;
    white-space: nowrap;
  }
  .plat-table-assets-th {
    border: none !important;
    width: 25.2% !important;
    margin-left: 8px !important;
  }
  .head-foot-title {
    margin-right: -85px;
  }
  .asset-body-content-app {
    width: 25.45% !important;
    margin-right: 0.3px;
  }
  .plat-table-assets-th-footer {
    width: 25% !important;
    margin-left: 9.5px !important;
    margin-top: -16px !important;
  }
  .cloud-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 92.3% !important;
    margin-left: 32px;
  }
  .environment-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 94% !important;
    margin-left: 16px;
  }
  .application-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 86.6%;
    margin-left: 94px;
  }
}
@media only screen and (max-width: 1601px) and (min-width: 1472px) {
  .plat-table-assets-th {
    border: none !important;
    width: 25.15% !important;
    margin-left: 8px !important;
  }
  .head-foot-title {
    margin-right: -80px;
  }
  .asset-body-content-app {
    width: 25.4% !important;
    margin-right: 0.3px;
  }
  .plat-table-assets-th-footer {
    width: 25.13% !important;
    margin-left: 8px !important;
    margin-top: -16px !important;
  }
  .cloud-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 92.2% !important;
    margin-left: 32px;
  }
  .application-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 86%;
    margin-left: 94px;
  }
}
@media only screen and (max-width: 1367px) and (min-width: 1282px) {
  .plat-table-assets-th {
    border: none !important;
    width: 24.95% !important;
    margin-left: 8px !important;
  }
  .head-foot-title {
    margin-right: -66px;
  }
  .environment-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 93.4% !important;
    margin-left: 16px;
  }
  .cloud-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 91.5% !important;
    margin-left: 32px;
  }
  .asset-body-content-app {
    width: 25.25% !important;
    margin-right: 0.3px;
  }
  .application-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 84.3%;
    margin-left: 94px;
  }
}
@media only screen and (max-width: 1081px) and (min-width: 1026px) {
  .asset-body-content {
    width: 26.76% !important;
    margin-right: 0.3px;
  }
  .asset-table-body-main-title {
    margin-top: 17px !important;
    margin-left: -3px !important;
    font-weight: 500;
    font-size: 12.24px;
    line-height: 18px;
    white-space: pre;
  }
  .asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 97.4%;
    margin-left: -4px;
  }
  .environment-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 94.2% !important;
    margin-left: 16px;
  }
  .cloud-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 91.5% !important;
    margin-left: 32px;
  }
  .asset-table-down-product-title {
    padding-left: 75px !important;
    margin-top: 11px !important;
    margin-right: 30px;
  }
  .application-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 87.2%;
    margin-left: 63px;
  }
  .plat-table-assets-th-footer {
    width: 25.1% !important;
    margin-left: 8.3px !important;
    margin-top: -16px !important;
  }
}
@media only screen and (max-width: 1081px) and (min-width: 770px) {
  .asset-body-content {
    width: 27.3% !important;
    margin-right: 0.3px;
  }
  .plat-table-assets-th {
    border: none !important;
    width: 25.5% !important;
    margin-left: 7.8px !important;
  }
  .head-foot-title {
    margin-right: -53px;
  }
  .asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 99.2%;
    margin-left: -4px;
  }
  .environment-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 95.7% !important;
    margin-left: 16px;
  }
  .cloud-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 93% !important;
    margin-left: 32px;
  }
  .asset-table-down-product-title {
    padding-left: 81px !important;
    margin-top: 11px !important;
    margin-right: 28px;
  }
  .asset-body-content-app {
    width: 25.86% !important;
    margin-right: 0.3px;
  }
  .application-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 87.5%;
    margin-left: 68px;
  }
  .plat-table-assets-th-footer {
    width: 25.4% !important;
    margin-left: 9px !important;
    margin-top: -16px !important;
  }
}
@media only screen and (max-width: 769px) and (min-width: 428px) {
  .asset-body-content {
    width: 26.7% !important;
    margin-right: 0.3px;
  }
  .asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 97.2%;
    margin-left: -4px;
  }
  .environment-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 94.1% !important;
    margin-left: 16px;
  }
  .asset-table-down-product-title {
    padding-left: 85px !important;
    margin-top: 11px !important;
    margin-right: 29px;
  }
  .application-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 86%;
    margin-left: 72px;
  }
  .plat-table-assets-th-footer {
    width: 25% !important;
    margin-left: 9px !important;
    margin-top: -16px !important;
  }
  .plat-accordion-size {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
}
@media only screen and (max-width: 426px) {
  .monile-environment-icon {
    margin: -404px 0px 0px 23px !important;
  }
  .assets-table-top-header {
    display: none !important;
  }
  .accordion-desktop-view {
    display: none !important;
  }
  .accordion-mobile-view {
    display: block !important;
  }
  .plat-table-assets-th-mobile {
    text-align: center;
  }
  .asset-table-body-list {
    font-size: 12px;
    width: 118% !important;
  }
  /* .assets-table-top-header {
        font-size: 20px;
        margin-top: 12px;
        color: #063f73;
        font-weight: bold;
    } */
  .cd-accordion__label--icon-folder::before {
    color: var(--site_color);
    content: "v" !important;
    display: none;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    margin: -271px 11px 0px 20px !important;
    font-size: 17px;
  }
  .asset-table-body-main-title {
    padding: 20px;
    /* margin-top: 11px !important; */
    /* margin-left: 11px !important; */
    text-align: center;
    font-size: 18px;
    width: 100% !important;
  }
  .assets-table-body-mobile {
    background: #ecf4ff;
    border-radius: 12px;
    margin-left: 19px;
    width: 100% !important;
  }
  .assets-table-body-set-text {
    padding: 17px 0px;
  }
  .assets-body-count-text {
    font-size: 15px;
    font-weight: 500;
    color: #063f73;
    margin-left: 30px;
  }
  .assets-body-spend-text {
    font-size: 15px;
    font-weight: 500;
    color: #063f73;
    margin-left: 152px;
  }
  .assets-table-body-0-set {
    background: #ecf4ff !important;
    padding: 10px 0px 23px 0px !important;
    width: auto !important;
  }
  .assets-table-body-set {
    display: flex;
    /* background: #84aee7; */
    width: auto;
  }
  .assets-body-count {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    text-align: center !important;
  }
  .assets-body-spend {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    margin-right: 28px;
    white-space: nowrap;
  }
  .assets-table-header-title {
    background-color: var(--site_color) !important;
    color: #fff !important;
    font-size: 15px !important;
    border-radius: 5px !important;
    text-align: center !important;
    margin-bottom: 0px;
    /* margin-left: 113px !important; */
  }
  .asset-symble {
    padding: 0px !important;
    margin: -366px 0px 0px 23px !important;
    font-size: 25px;
    color: var(--site_color);
    height: 32px;
  }
  .application-assets-table-body-mobile {
    margin-left: 6px;
  }
  /*vm*/
  .vm-asset-table-body-list {
    margin-left: 0px !important;
  }
  .vm-assets-table-body-0-set {
    background: #ecf4ff;
    padding: 8px 0px 0px 0px;
    margin: 0px 0px 0px 18px;
  }
  .vm-assets-body-count {
    color: #000;
    text-align: right !important;
    font-weight: 400;
    font-size: 12.24px;
    line-height: 18px;
    /* padding-left: 22px; */
    width: 50%;
  }
  .environment-list-title {
    padding-left: 18px !important;
  }
  .vm-environment-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 100% !important;
    margin-left: 17px;
  }
  .cloud-list-title {
    padding-left: 23px !important;
  }
  .vm-cloud-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 100% !important;
    margin-left: 28px;
  }
  .vm-asset-table-down-product-title {
    margin-left: 0px !important;
    padding-left: 33px !important;
    margin-top: 11px !important;
  }
  .vm-application-asset-spend-border-bottom {
    border-bottom: 0.37px solid #848484;
    width: 100% !important;
    margin-left: 33px;
  }
  .vm-cloud-spend {
    margin-left: 18px !important;
  }
  .vm-table-main-body {
    margin: 0px 0px 0px 18px;
    background-color: #ecf4ff !important;
  }
  .vm-all-environment-icon {
    margin: -22px 0px -25px 8px !important;
    font-size: 15px !important;
  }
  .vm-asset-table-body-main-title {
    margin-top: 17px !important;
    margin-left: 0px !important;
    font-weight: 500;
    font-size: 12.24px;
    line-height: 18px;
    white-space: nowrap;
    padding-left: 17px !important;
  }
  .vm-environment-list-icon {
    margin: -21px 0px -20px 8px !important;
    font-size: 15px !important;
  }
  .vm-cloud-list-icon {
    margin: -21px 0px -20px 8px !important;
    font-size: 15px !important;
  }
  .cd-accordion__sub {
    display: none;
    overflow: inherit;
  }
}
@media only screen and (max-width: 376px) {
  .cd-accordion__label--icon-folder::before {
    color: var(--site_color);
    content: "v" !important;
    display: none;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    margin: -281px 11px 0px 20px !important;
    font-size: 17px;
  }
  .assets-body-spend-text {
    font-size: 15px;
    /* font-weight: bold; */
    color: #063f73;
    margin-left: 116px;
  }
  .assets-body-spend {
    font-size: 13px;
    font-weight: bold;
    color: #000;
    padding-left: 81px;
  }
  .assets-table-body-mobile {
    background: #ecf4ff;
    border-radius: 12px;
    margin-left: 19px;
    width: 90% !important;
  }
  .cd-accordion__sub {
    display: none;
    overflow: inherit;
  }
}
@media only screen and (max-width: 321) {
  .asset-symble {
    padding: 0px !important;
    margin: -367px 0px 0px 23px !important;
    font-size: 25px;
    color: var(--site_color);
    height: 32px;
  }
  .assets-body-spend-text {
    font-size: 15px;
    /* font-weight: 500; */
    color: #063f73;
    margin-left: 116px;
  }
  .assets-body-spend {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    padding-left: 0px;
  }
  .assets-table-body-mobile {
    background: #ecf4ff;
    border-radius: 12px;
    margin-left: 20px !important;
    width: 100% !important;
  }
  .asset-table-body-main-title {
    padding: 20px;
    /* margin-top: 11px !important; */
    /* margin-left: 11px !important; */
    text-align: center;
    font-size: 18px;
    width: 291px !important;
  }
  .cd-accordion__sub {
    display: none;
    overflow: inherit;
  }
}
