:root {
  --peems-site-color: #1c344c;
}
.filter-card {
  padding: 0.2rem 1.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
}

.job-card {
  display: flex;
  justify-content: center;
}

.job-cards .ant-card {
  border-radius: 11px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 11.1579px;
  border: none;
  width: 100%;
  margin: 0 1.5rem;
}

.job-cards .ant-card-body {
  padding: 1.2rem 2rem;
}

.job-cards .job-card-initial {
  margin: 0 2rem 0 0;
}

.total-job-count {
  color: var(--peems-site-color);
  font-weight: 600;
}

.job-status-icon,
.job-status-text {
  color: var(--peems-site-color);
  font-size: 16px;
}
.job-status-icon svg {
  font-size: 20px;
}

.job-progress .ant-progress-bg {
  background: linear-gradient(
    77.89deg,
    #a41b1c 16.3%,
    #de8807 33.7%,
    #e9ed12 53.86%,
    #04dd00 75.06%,
    #1a8131 94.61%
  );
}

.peems.ant-table-wrapper .ant-table-thead > tr > th {
  color: var(--white-color) !important;
  background-color: var(--spp_site_color) !important;
  /* overflow-x: auto !important; */
}
.peems.ant-table-wrapper .ant-table {
  background-color: var(--white-color) !important;
  color: var(--spp_site_color) !important;
  /* overflow-x: auto !important; */
}

.peems.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: var(--slm_site_color) !important;
  color: var(--white-color) !important;
  padding: 17px 16px !important;
}

.peems.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: var(--slm_site_color) !important;
  color: var(--white-color) !important;
  border-bottom: 0px !important;
}
.job-schedule-table {
  height: 200px;
  overflow-x: auto;
}

tr.ant-table-expanded-row td.ant-table-cell {
  padding: 16px 0px;
  background: #f6fbff !important;
}

.ant-table-expanded-row td.ant-table-cell {
  background: grey !important;
}

.job-schedule-table table thead tr th {
  font-weight: 600;
}

.peems-completed {
  border: 2px solid #1f6d1e;
  /* padding: 1px; */
  display: flex;
  justify-content: center;
  padding: 7px 0;
  border-radius: 4px;
  color: #1f6d1e;
  width: 75%;
}

.peems-active,
.peems-not-trigerred,
.peems-failed {
  border: 2px solid #be1e2d;
  /* padding: 1px; */
  display: flex;
  justify-content: center;
  padding: 7px 0;
  border-radius: 4px;
  color: #be1e2d;
  width: 75%;
}

.peems-running {
  border: 2px solid #1895f0;
  /* padding: 1px; */
  display: flex;
  justify-content: center;
  padding: 7px 0;
  border-radius: 4px;
  color: #1895f0;
  width: 75%;
}

.peems-color {
  color: var(--peems-site-color) !important;
}

.react-js-cron {
  display: flex;
  /* justify-content: space-between; */
}
div.react-js-cron-select {
  margin-left: 5px;
  /* width: 114px; */
}
