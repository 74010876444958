.datepicker{
    background-color: #ffffffff!important;
    width: 100% !important;
    border-radius: 0.4rem  !important;
    border-color: #d9d9d9!important;
    line-height: 1.8rem!important;

}
.datepicker:hover{
    border-color: #4096ff!important;
    border-inline-end-width: 1px;
}

