.operation-main-iframe {
    width: 100%;
    height: 100vh;
}
.planned_open_activity {
    height: 100vh;
}
.operation-menu {
    margin: 0px -9px;
    width: 33px;
}
.plat-operation-body {
    margin-top: 23px !important;
    margin-left: 3.6%;
}
.plat-table-date {
    white-space: nowrap;
}